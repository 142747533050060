import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  useLayoutEffect,
  use,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import JsSIP from "jssip";

import axios from "axios";
import { Routes, Route, useLocation } from "react-router-dom";
import {
  Layout,
  Menu,
  Typography,
  Button,
  Avatar,
  Drawer,
  message,
} from "antd";
import {
  HomeOutlined,
  LogoutOutlined,
  UserOutlined,
  LaptopOutlined,
  NotificationOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from "@ant-design/icons";
import loginSlice from "./redux/loginSlice";
import useAxios from "./CustomHooks/useAxios";

import "./App.css";
import "./Search.css";
import { checkLoginStatusInLocalStorage } from "./mylibs/list1";
import Home from "./components/Home/Home";
import Dashboard from "./components/Dashboard/Dashboard";
import CallLog from "./components/CallLog/CallLog";
import Login from "./components/Login/Login";
import MyPhone from "./components/MyPhone/MyPhone";
import MyMenu from "./components/MyMenu/MyMenu";

import MenuSub from "./components/SettingsMenu/MenuSub";
import MenuItem from "./components/SettingsMenu/MenuItem";
import AuthorizationGroup from "./components/SettingsMenu/AuthorizationGroup";
import AuthorizationGroupItem from "./components/SettingsMenu/AuthorizationGroupItem";

import Itsps from "./components/SettingsTel/Itsps";
import Companies from "./components/SettingsTel/Companies";
import Tels from "./components/SettingsTel/Tels";
import Agents from "./components/SettingsTel/Agents";
import BlackList from "./components/SettingsTel/BlackList";

import SurveyPlan from "./components/SettingsTel/SurveyPlan";
import Department from "./components/SettingsTel/Department";
import CalloutRule from "./components/SettingsTel/CalloutRule";

import IVRPlanMain from "./components/SettingsIVR/IVRPlanMain";
import IVRPlanForDaily from "./components/SettingsIVR/IVRPlanForDaily";

import IVRPlanForKey from "./components/SettingsIVR/IVRPlanForKey";
import IVRPlanForDay from "./components/SettingsIVR/IVRPlanForDay";
import IVRPlanForWeekday from "./components/SettingsIVR/IVRPlanForWeekday";
import Contacts from "./components/Customers/Contacts";
import OutBoundData from "./components/Customers/OutBoundData";
import OutboundPlan from "./components/Customers/OutboundPlan";
import AgentOutboundData from "./components/Customers/AgentOutboundData";

import ZnsPlan from "./components/Zalo/ZnsPlan";
import ZnsData from "./components/Zalo/ZnsData";
import SendSMS from "./components/SMS/SendSMS";
import SMSThuongHieu from "./components/SMS/SMSThuongHieu";

import Tickets from "./components/Tickets/Tickets";

import AutoCall from "./components/AutoCall/AutoCall";
import SurveyData from "./components/AutoCall/SurveyData";
import SurveyCurrentCall from "./components/AutoCall/SurveyCurrentCall";

import AgentStatus from "./components/CallCenter/AgentStatus";
import AgentLogs from "./components/CallCenter/AgentLogs";
import SurveyAfterCall from "./components/CallCenter/SurveyAfterCall";
import GetIP from "./jsxcompo/GetIP";
import Pause from "./Pause";
import Profile from "./Profile";
import {
  registerMakeCall,
  setRegisterStatus,
  addCallInfo_Ticket,
  delCallInfo_Ticket,
  setprovinceDataRedux,
} from "./redux/jsSIPSlice";
import QuickCall from "./components/QuickCall";
import CurrentCall from "./components/CallCenter/CurrentCall";
import CallIn from "./components/CallIn";
import CallOut from "./components/CallOut";
import TicketNote from "./components/TicketNote";
import TicketNoteIn from "./components/TicketNoteIn";
import MicrophoneRequest from "./components/Permission/MicrophoneRequest";
import AudioDevices from "./jsxcompo/AudioDevices";
import StunTurn from "./jsxcompo/StunTurn";

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;
const { Title } = Typography;
function getFaviconEl() {
  return document.getElementById("favicon");
}
const DEFAULT_TIME_ZONE = "Asia/Ho_Chi_Minh";
const formatDate = (date, options) =>
  new Intl.DateTimeFormat("vi-VN", {
    timeZone: DEFAULT_TIME_ZONE,
    ...options,
  }).format(date);
function App() {
  let isLogined = useSelector((state) => state.loginReducer.isLogined);
  let webrtc = useSelector((state) => state.loginReducer.webrtc);

  const [ringToneTime, setRingToneTime] = useState(0);
  const [remoteAudioTime, setRemoteAudioTime] = useState(0);
  const [transferAudioTime, setTransferAudioTime] = useState(0);

  const sessionCallTransferRef = useRef(null);
  const ringToneRef = useRef(null);
  const endToneRef = useRef(null);
  const remoteAudioRef = useRef(null);
  const transferAudioRef = useRef(null);

  //const [audioUrl, setAudioUrl] = useState(null); // URL của file âm thanh được lưu
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]); // Lưu các blob âm thanh

  const { axiosi } = useAxios();
  const [hideItem, setHideItem] = useState("hideItem");
  const [userAvatar, setUserAvatar] = useState("userRegistered");
  const [collapsed, setCollapsed] = useState(false);

  const [refreshCallLog, setRefreshCallLog] = useState(false);
  const [displayRegisterStatus, setDisplayRegisterStatus] = useState("");
  const [pauseStatus, setPauseStatus] = useState(false);
  const pauseStatusRef = useRef(false);
  const [pauseReason, setPauseReason] = useState("");
  const [visiblePause, setVisiblePause] = useState(false);
  const [visibleProfile, setVisibleProfile] = useState(false);
  const [visibleAudioDevices, setVisibleAudioDevices] = useState(false);
  const [visibleStunTurn, setVisibleStunTurn] = useState(false);
  const [showSTUNTURNMenu, setShowSTUNTURNMenu] = useState(false);
  const [stunTurnMode, setStunTurnMode] = useState("");
  const user = useSelector((state) => state.loginReducer.user);
  const userRef = useRef(user);

  const enableDebug = useSelector((state) => state.loginReducer.enableDebug);
  const enableDebugRef = useRef(enableDebug);
  const debugLogs = useRef([]);
  // const [debugLogs, setDebugLogs] = useState([]);

  const endpointid = useSelector((state) => state.loginReducer.endpointid);
  // const [sipUser, setSipUser] = useState("201");
  let isRegister = useSelector((state) => state.jsSIPReducer.isRegister);

  // const [sipUser, setSipUser] = useState("6302");
  const [mySessionsId, setMySessionsId] = useState([]);
  const [mySessionsIdTicket, setMySessionsIdTicket] = useState([]);

  const dispatch = useDispatch();
  const location = useLocation();

  const [selectSubMenu, setSelectSubMenu] = useState("");

  let audioPlayers = useSelector((state) => state.jsSIPReducer.audioPlayers);
  const [provinceData, setProvinceData] = useState(null);
  const [refreshRegister, setRefreshRegister] = useState(false);
  // const handleGoogle = () => {
  //   const favicon = getFaviconEl(); // Accessing favicon element
  //   favicon.href = "https://www.google.com/favicon.ico";
  // };

  // const handleYoutube = () => {
  //   const favicon = getFaviconEl();
  //   favicon.href = " https://s.ytimg.com/yts/img/favicon-vfl8qSV2F.ico";
  // };

  /////////////////////////////////
  const uploadAudio = async (audioBlob, tel) => {
    try {
      const formData = new FormData();
      // const tel=mysessionInfo?.tel;
      formData.append("uniqueid", tel);
      // formData.append("tel", mysessionInfo?.tel);
      formData.append("file", audioBlob, `${tel}.webm`);
      // formData.append(uniqueid, audioBlob, `${uniqueid}.webm`);

      const response = await axiosi.post(
        `${process.env.REACT_APP_API_URL}/debuglog/uploadAudio`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("Upload successful:", response.data);
    } catch (error) {
      console.error("Upload failed:", error);
    }
  };
  const handleStopRecording = () => {
    console.log("[handleStopRecording]: Begin");
    let enable_debug = userRef.current.agents
      ? userRef.current.agents[0]?.enable_debug
      : 0;
    if (!enable_debug) return;
    addDebugLogMessage("[handleStopRecording]: Recording stopped");
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state !== "inactive"
    ) {
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current = null;
    }
  };
  const handleRecordAudio = (stream, tel) => {
    addDebugLogMessage("[handleRecordAudio]: Begin");
    // addDebugLogMessage(userRef.current.agents);
    let enable_debug = userRef.current.agents
      ? userRef.current.agents[0]?.enable_debug
      : 0;
    addDebugLogMessage("[handleRecordAudio]: enable_debug=" + enable_debug);
    if (!enable_debug) {
      addDebugLogMessage(
        "[handleRecordAudio]: khong co enable_debug nen  kg ghi am"
      );
      return;
    }

    addDebugLogMessage(
      "[handleRecordAudio]: Kiem tra mediaRecorderRef.current co hay khong"
    );
    if (!mediaRecorderRef.current) {
      try {
        const mediaRecorder = new MediaRecorder(stream);
        mediaRecorderRef.current = mediaRecorder;

        mediaRecorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            audioChunksRef.current.push(event.data);
          }
        };

        mediaRecorder.onstop = () => {
          const audioBlob = new Blob(audioChunksRef.current, {
            type: "audio/webm",
          });
          // const audioUrl = URL.createObjectURL(audioBlob);
          // setAudioUrl(audioUrl); // Lưu URL để có thể tải xuống
          uploadAudio(audioBlob, tel);
          audioChunksRef.current = []; // Reset chunks
        };

        mediaRecorder.start();
        addDebugLogMessage("[handleRecordAudio]: Recording started");
      } catch (error) {
        console.error("Failed to start MediaRecorder:", error);
        addDebugLogMessage(
          "[handleRecordAudio]: Failed to start MediaRecorder :" + error.message
        );
      }
    }
  };
  const addDebugLog = (log) => {
    // console.log("[addDebugLog]1: log=", log,debugLogs.current);
    let enable_debug = userRef.current.agents
      ? userRef.current.agents[0]?.enable_debug
      : 0;
    if (!enable_debug) return;
    const now = new Date();
    const time = formatDate(now, {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    });
    const dataLog = { time, ...log };
    // console.log("[addDebugLog]2:debugLogs", debugLogs.current);
    debugLogs.current.push(dataLog);
    // console.log("[addDebugLog]3:debugLogs", debugLogs.current);
  };
  const addDebugLogMessage = (message) => {
    let enable_debug = userRef.current.agents
      ? userRef.current.agents[0]?.enable_debug
      : 0;

    if (!enable_debug) return;
    addDebugLog({ message });
    // console.log("[addDebugLogMessage]2:debugLogs", debugLogs);
  };
  const resetDebugLog = () => {
    debugLogs.current = [];
    // console.log("[resetDebugLog]1:debugLogs", debugLogs);
    // setDebugLogs([{"message":"resetDebugLog"}]);
    // console.log("[resetDebugLog]2:debugLogs", debugLogs);
  };
  async function saveDebugLogEnd() {
    let data = {};
    try {
      data["from"] = "web";

      if (!userRef.current?.agents) {
        return;
      }

      let enable_debug = userRef.current.agents
        ? userRef.current.agents[0]?.enable_debug
        : 0;

      if (enable_debug === 1) {
        console.log("[saveDebugLogEnd]1:debugLogs.current=", debugLogs.current);

        let debugLogs_hientai = debugLogs.current.slice();
        debugLogs.current = [];
        const debugLogs_hientai_length = debugLogs_hientai.length;

        data["debugLogs"] = debugLogs_hientai;
        console.log("[saveDebugLogEnd]2:data=", data);
        const kq = await axiosi.put(`debuglog/saveLog`, data);
        // for(let i=0;i<debugLogs_hientai_length;){
        //   data["debugLogs"] = debugLogs_hientai.slice(i,i+100);
        //   console.log("[saveDebugLogEnd]2:data=",{i,data});
        //   const kq = await axiosi.put(`debuglog/saveLog`, data);
        //   console.log("[saveDebugLogEnd]3:kq=",kq,debugLogs.current);
        //   i+=100;
        // }
      }
    } catch (error) {
      console.log("[saveDebugLogEnd-error] :data=", data);
      console.log("[saveDebugLogEnd]: error=", error.message);
    }
  }

  //////////////////////////////////
  const onClickSendNotification = (telinnumber) => {
    if (document.visibilityState === "hidden") {
      Notification.requestPermission().then((result) => {
        if (result === "granted") {
          const tb = new Notification(telinnumber, {
            body: "incoming call...",
          });
          // tb.onclick = (event) => {
          //   console.log("click3");

          // if (window.focus) {
          //   window.focus();
          // }

          // };
          tb.onshow = () => {
            addDebugLog({
              telinnumber,
              Notification: "Thong bao da hien thi!",
            });
            tb.onclick = (event) => {
              console.log("click2");
              addDebugLog({ telinnumber, Notification: "click2" });
              //event.preventDefault(); // Ngăn chặn hành vi mặc định
              // Kiểm tra nếu cửa sổ đang mở, thì kích hoạt lại nó
              if (window.focus) {
                window.focus();
              }
              // else {
              //   // Nếu không, mở tab mới với URL của bạn
              //   window.open("https://yourwebsite.com", "_blank");
              // }
            };
          };
        } else {
          console.log("not granted");
          addDebugLog({ errorMessage: "Notification permission denied" });
        }
      });
    } else {
      console.log("already visible");
      addDebugLog({ telinnumber, Notification: "visible" });
    }
  };
  const showSendNotification = (message) => {
    
      const messageJson=JSON.parse(message);
      let title=`Survey Result of Tel=${messageJson.tel}`;
      let body="Customer hasn't pressed any key!";
      if (messageJson.ivrkey) {
        body = `Customer has pressed key:  ${messageJson.ivrkey}`;
      }

      Notification.requestPermission().then((result) => {
        if (result === "granted") {
          const tb = new Notification(body, {
            body: title,
          });
          
          tb.onshow = () => {
            addDebugLog({
              message,
              Notification: "Thong bao svc da hien thi!",
            });
            
          };
        } else {
          console.log("not granted");
          addDebugLog({ errorMessage: "Notification permission denied" });
        }
      });
    
  };
  const setRingSpeaker = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      const speakerDevices = await navigator.mediaDevices.enumerateDevices();
      addDebugLog({ speakerDevices });
      const audioOutputs = speakerDevices.filter(
        (device) => device.kind === "audiooutput"
      );
      addDebugLog({ audioOutputs });

      const ringSpeaker = localStorage.getItem("ringSpeaker") || "";
      const talkSpeaker = localStorage.getItem("talkSpeaker") || "";

      try {
        const audioUrl = ringToneRef.current.src; // "./media/doremon.mp3";

        remoteAudioRef.current.src = audioUrl;
        transferAudioRef.current.src = audioUrl;

        await ringToneRef.current.setSinkId(ringSpeaker);
        await endToneRef.current.setSinkId(talkSpeaker);
        await remoteAudioRef.current.setSinkId(talkSpeaker);
        await transferAudioRef.current.setSinkId(talkSpeaker);

        console.log(`da dat setSinkId: ${ringSpeaker} || ${talkSpeaker}`);
        addDebugLogMessage(
          `[useEffect_setSinkId]:setSinkId= ${ringSpeaker} || ${talkSpeaker}`
        );
        saveDebugLogEnd();
      } catch (err) {
        console.error(
          `[playRingTone]: Lỗi khi đặt setSinkId (${ringSpeaker}):`,
          err.message
        );
        addDebugLogMessage(`[useEffect_setSinkId]:Error= ${err.message}`);
        saveDebugLogEnd();
      }
    } catch (err) {
      addDebugLogMessage(
        `[useEffect_setSinkId_mediaDevices]:Error= ${err.message}`
      );
      saveDebugLogEnd();
    }
  };
  const handleFaviIcon = (icon) => {
    return;
    // const favicon = getFaviconEl();
    // favicon.href = "./img/" + icon; //favicon_ring.ico";
  };
  // useEffect(() => {
  //   if (navigationType === 'reload') {
  //     console.log('==============Trang đã được refresh!');
  //     // Xử lý logic khi refresh trang tại đây
  //   }
  // }, [navigationType]);

  const removeSession = (sessionId) => {
    delete mysessionsInfo.current[sessionId];
    delete mysessions.current[sessionId];
    setMySessionsId((prev) => prev.filter((id) => id !== sessionId));
    setRefreshCallLog((pre) => !pre);
  };
  useEffect(() => {
    const audio = ringToneRef.current;
    if (!audio) {
      return;
    }
    // Định nghĩa callback
    const handleCanPlay = () => addDebugLogMessage("[ringTone]: canplay");
    const handleEnded = () => addDebugLogMessage("[ringTone]: ended");
    const handleTimeUpdate = () => {
      setRingToneTime(audio.currentTime);
      addDebugLogMessage(`[ringTone]: timeupdate: ${audio.currentTime}`);
    };
    const handleError = () => addDebugLogMessage("[ringTone]: error");
    const handleStalled = () => addDebugLogMessage("[ringTone]: stalled");
    const handleSuspend = () => addDebugLogMessage("[ringTone]: suspend");
    const handleAbort = () => addDebugLogMessage("[ringTone]: abort");

    audio.addEventListener("canplay", handleCanPlay);
    audio.addEventListener("ended", handleEnded);
    audio.addEventListener("timeupdate", handleTimeUpdate);
    audio.addEventListener("error", handleError);
    audio.addEventListener("stalled", handleStalled);
    audio.addEventListener("suspend", handleSuspend);
    audio.addEventListener("abort", handleAbort);
    return () => {
      if (audio) {
        audio.removeEventListener("canplay", handleCanPlay);
        audio.removeEventListener("ended", handleEnded);
        audio.removeEventListener("timeupdate", handleTimeUpdate);
        audio.removeEventListener("error", handleError);
        audio.removeEventListener("stalled", handleStalled);
        audio.removeEventListener("suspend", handleSuspend);
        audio.removeEventListener("abort", handleAbort);
      }
    };
  }, [enableDebug]);
  useEffect(() => {
    const audio = remoteAudioRef.current;
    if (!audio) {
      return;
    }
    // Định nghĩa callback
    const handleCanPlay = () => addDebugLogMessage("[remoteAudio]: canplay");
    const handleEnded = () => addDebugLogMessage("[remoteAudio]: ended");
    const handleTimeUpdate = () => {
      setRemoteAudioTime(audio.currentTime);
      addDebugLogMessage(`[remoteAudio]: timeupdate: ${audio.currentTime}`);
    };
    const handleError = () => addDebugLogMessage("[remoteAudio]: error");
    const handleStalled = () => addDebugLogMessage("[remoteAudio]: stalled");
    const handleSuspend = () => addDebugLogMessage("[remoteAudio]: suspend");
    const handleAbort = () => addDebugLogMessage("[remoteAudio]: abort");

    const handleLoadStart = () => {
      addDebugLogMessage("[remoteAudio]: loadstart");
    };
    const handleProgress = () => {
      addDebugLogMessage("[remoteAudio]: progress");
    };
    const handleWaiting = () => {
      addDebugLogMessage("[remoteAudio]: waiting");
    };
    const handlePlaying = () => {
      addDebugLogMessage("[remoteAudio]: playing");
    };

    const handleEmptied = () => {
      addDebugLogMessage("[remoteAudio]: emptied");
    };

    audio.addEventListener("loadstart", handleLoadStart);
    audio.addEventListener("progress", handleProgress);
    audio.addEventListener("waiting", handleWaiting);
    audio.addEventListener("playing", handlePlaying);

    audio.addEventListener("emptied", handleEmptied);

    audio.addEventListener("canplay", handleCanPlay);
    audio.addEventListener("ended", handleEnded);
    audio.addEventListener("timeupdate", handleTimeUpdate);
    audio.addEventListener("error", handleError);
    audio.addEventListener("stalled", handleStalled);
    audio.addEventListener("suspend", handleSuspend);
    audio.addEventListener("abort", handleAbort);
    return () => {
      if (audio) {
        audio.removeEventListener("canplay", handleCanPlay);
        audio.removeEventListener("ended", handleEnded);
        audio.removeEventListener("timeupdate", handleTimeUpdate);
        audio.removeEventListener("error", handleError);
        audio.removeEventListener("stalled", handleStalled);
        audio.removeEventListener("suspend", handleSuspend);
        audio.removeEventListener("abort", handleAbort);

        audio.removeEventListener("loadstart", handleLoadStart);
        audio.removeEventListener("progress", handleProgress);
        audio.removeEventListener("waiting", handleWaiting);
        audio.removeEventListener("playing", handlePlaying);

        audio.removeEventListener("emptied", handleEmptied);
      }
    };
  }, [enableDebug]);

  useEffect(() => {
    enableDebugRef.current = enableDebug; // Cập nhật giá trị mới nhất
  }, [enableDebug]);
  useEffect(() => {
    userRef.current = user; // Cập nhật giá trị mới nhất
  }, [user]);

  useEffect(() => {
    async function getUser() {
      console.log("Will getUser() : api post users/getUser");
      const kq = await axiosi.post("users/getUser");
      console.log("Will getUser() : api post users/getUser : DOne");
      dispatch(loginSlice.actions.setUser({ user: kq.data }));
    }
    const handleRefresh = () => {
      if (performance.navigation.type === performance.navigation.TYPE_RELOAD) {
        console.log("============Trang đã được refresh!");
        getUser();
        dispatch(loginSlice.actions.setEnableDebug({ enableDebug: 2 }));
        // Xử lý logic khi refresh trang tại đây
      }
    };

    window.addEventListener("load", handleRefresh);
    return () => window.removeEventListener("load", handleRefresh);
  }, []);
  useEffect(() => {
    // handleFaviIcon("favicon_ring.ico");
    const getProvince = async () => {
      try {
        const kq = await axiosi.get("province", { params: { pageSize: 100 } });
        console.log(
          "[App-useEffect-getProvince]:kq get province : ",
          kq.data.rows
        );
        setProvinceData(kq.data.rows);
        dispatch(setprovinceDataRedux(kq.data.rows));
      } catch (error) {
        console.log("[Effect-getProvince]:Error");
      }
    };
    if (isLogined) {
      getProvince();
    }
  }, [isLogined, dispatch]);
  useEffect(() => {
    dispatch(
      loginSlice.actions.setLogin({
        isLogined: checkLoginStatusInLocalStorage(),
      })
    );
  }, [dispatch]);

  useEffect(() => {
    async function getUser() {
      console.log("Will getUser() : api post users/getUser");
      const kq = await axiosi.post("users/getUser");
      console.log("Will getUser() : api post users/getUser : DOne");
      dispatch(loginSlice.actions.setUser({ user: kq.data }));
    }
    if (isLogined && !user.agents) {
      console.log("Will getUser()");
      getUser();
    }
  }, [isLogined, user, axiosi, dispatch]);

  useEffect(() => {
    if (userRef.current?.agents) {
      let nat_traversal = userRef.current?.agents[0]?.nat_traversal;
      nat_traversal = nat_traversal.replace(/\s+/g, " ");
      // console.log({ nat_traversal });
      // console.log(nat_traversal);
      let pcConfig = JSON.parse(nat_traversal);

      if (
        typeof pcConfig === "object" &&
        pcConfig.hasOwnProperty("iceServers")
      ) {
        if (
          Array.isArray(pcConfig.iceServers) &&
          pcConfig.iceServers.length > 0
        ) {
          setShowSTUNTURNMenu(true);
        }
      }
    }
  }, [userRef.current]);
  useLayoutEffect(() => {
    if (user.agents) {
      let curpathname = location.pathname;
      const curpathnameArr = curpathname.split("/");
      curpathname = "/" + curpathnameArr[1];
      const menuitem = user.menu.menu_items;
      const item = menuitem.find((i) => i.item_key === curpathname);

      if (item) {
        console.log("[handleGetSubMenu]:Tim` thay item", curpathname);
        const menusubs = user.menu.menu_subs;
        const sub = menusubs.find((s) => s.id === item.menu_sub_id);
        console.log("sub=", sub);
        setSelectSubMenu(sub.menukey);
        console.log("sub.menukey=", sub.menukey);
      } else {
        console.log("[handleGetSubMenu]:Kg tim thay Item", curpathname);
      }
    } else {
      console.log("[handleGetSubMenu]:kg co' agents");
    }
  }, [
    selectSubMenu,
    location.pathname,
    user?.agents,
    user?.menu?.menu_items,
    user?.menu?.menu_subs,
  ]);
  const coolPhone = useRef(
    useSelector((state) => state.jsSIPReducer.coolPhone)
  );

  const mysessions = useRef({});
  const mysessionsInfo = useRef({});
  // console.log("mysessions=", mysessions.current);
  // console.log("mysessionsInfo=", mysessionsInfo.current);
  // console.log("mySessionsId=", mySessionsId);
  // const mySessionsId = useRef([]);
  //makecall
  const saveDebugLog_ok = (data = {}) => {
    try {
      console.log("saveDebugLog, enableDebug =", enableDebugRef.current);
      console.log("saveDebugLog, userRef =", userRef.current);
    } catch (error) {
      console.log(error.message);
    }
  };
  async function saveDebugLog(data = {}) {
    try {
      data["from"] = "web";
      // console.log("saveDebugLog, data=", data);
      // console.log("saveDebugLog, enableDebug =", enableDebugRef.current);
      // console.log("saveDebugLog, userRef =", userRef.current);
      // console.log(userRef.current?.agents);
      if (!userRef.current?.agents) {
        return;
      }
      // console.log("saveDebugLog : Herer");
      let enable_debug = userRef.current.agents
        ? userRef.current.agents[0]?.enable_debug
        : 0;
      // console.log("enable_debug=", enable_debug);
      if (enable_debug === 1) {
        const kq = await axiosi.put(`debuglog/saveLog`, data);
      }
    } catch (error) {
      console.log("Loi saveDebugLog1 :data=", data);
      console.log("Loi saveDebugLog1 :error=", error.message);
    }
  }

  const jssipCall = useCallback(
    (to, isForTransfer = false) => {
      to = to.trim();
      console.log(
        "[MAKECALL]:Object.keys(mysessionsInfo.current).length=",
        Object.keys(mysessionsInfo.current).length,
        { mysessionsInfo }
      );
      if (Object.keys(mysessionsInfo.current).length > 0 && !isForTransfer) {
        return;
      }

      if (to.length === 0) {
        return;
      }

      saveDebugLog({
        type: "call",
        message: `Call to ${to}`,
        data: { to },
      });
      console.log("[jssipCall]1:dang check stun_turn");
      let nat_traversal = userRef.current?.agents[0]?.nat_traversal;
      nat_traversal = nat_traversal.replace(/\s+/g, " ");
      // console.log({ nat_traversal });
      // console.log(nat_traversal);
      let pcConfig = JSON.parse(nat_traversal);

      var eventHandlers = {
        peerconnection: function (callEventData) {
          console.log("makeCall peerconnection", callEventData);
          saveDebugLog({
            call_status: "makeCall peerconnection",
            data: callEventData,
          });
        },
        connecting: function (callEventData) {
          console.log("makeCall connecting", callEventData);
          saveDebugLog({
            call_status: "makeCall connecting",
            data: callEventData.request.body,
          });
        },
        sending: function (callEventData) {
          console.log("makeCall sending", callEventData);
          saveDebugLog({
            call_status: "makeCall sending",
            data: callEventData.request.body,
          });
        },
        icecandidate: function (callEventData) {
          saveDebugLog({
            call_status: "makeCall icecandidate",
            data: callEventData?.candidate?.candidate,
          });
        },
        getusermediafailed: function (callEventData) {
          saveDebugLog({
            call_status: "makeCall getusermediafailed",
            data: callEventData,
          });
        },
        progress: function (e) {
          saveDebugLog({
            call_status: "call is in progress",
            data: e,
          });
        },
        failed: function (e) {
          delete mysessionsInfo.current[this.id];
          delete mysessions.current[this.id];

          setMySessionsId((prev) => prev.filter((id) => id !== this.id));
          setRefreshCallLog((pre) => !pre);

          addDebugLog({
            call_status: "makeCall failed",
            originator: e.originator,
          });

          if (e.originator === "remote") {
            playEndTone();
          }

          saveDebugLog({
            call_status: "makeCall failed",
            data: e,
            originator: e.originator,
          });

          handleStopRecording();
          saveDebugLogEnd();
        },
        ended: function (e) {
          delete mysessionsInfo.current[this.id];
          delete mysessions.current[this.id];

          setMySessionsId((prev) => prev.filter((id) => id !== this.id));
          setRefreshCallLog((pre) => !pre);
          console.log("End [eventHandlers]:e.originator=" + e.originator);

          if (e.originator === "remote") {
            console.log("[eventHandlers]:remote-> playEND");
            playEndTone();
          }

          saveDebugLog({
            call_status: "makeCall ended",

            originator: e.originator,
          });

          addDebugLog({
            call_status: "makeCall ended",

            originator: e.originator,
          });
          handleStopRecording();
          saveDebugLogEnd();
        },
        accepted: function (callEventData) {
          console.log("Call accepted");
          saveDebugLog({
            call_status: "makeCall accepted",
            data: callEventData,
          });
        },
        confirmed: function (e) {
          console.log("[eventHandlers]:call confirmed", this.id, e);
          mysessions.current[this.id].callstatus = "confirmed";

          saveDebugLog({
            call_status: "makeCall confirmed",
            originator: e.originator,
          });
        },
      };

      var options = {
        eventHandlers: eventHandlers,
        mediaConstraints: { audio: true, video: false },
      };
      console.log("[jssipCall]:dang check stun_turn");
      addDebugLogMessage("[jssipCall]:dang check stun_turn");
      if (typeof pcConfig === "object" && pcConfig.iceServers?.length > 0) {
        let [urls] = pcConfig.iceServers[0].urls.split("?");
        let stunTurnMode = localStorage.getItem("stun_turn");
        console.log("[jssipCall]:stunTurnMode=", stunTurnMode);
        if (stunTurnMode === "tcp" || stunTurnMode === "udp") {
          urls += `?transport=${stunTurnMode}`;
        }
        if (stunTurnMode !== "none") {
          pcConfig.iceServers[0].urls = urls;
          options.pcConfig = pcConfig;
          addDebugLog({ "options.pcConfig": options.pcConfig });
        } else {
          addDebugLog({ message: "stunTurnMode=none" });
        }
      }
      saveDebugLog({
        pcConfig,
      });
      // if (
      //   typeof pcConfig === "object" &&
      //   pcConfig.hasOwnProperty("iceServers")
      // ) {
      //   if (
      //     Array.isArray(pcConfig.iceServers) &&
      //     pcConfig.iceServers.length > 0
      //   ) {
      //     let urls = pcConfig.iceServers[0].urls;
      //     let urls_arr = urls.split("?");
      //     urls = urls_arr[0];
      //     let stunTurnMode = localStorage.getItem("stun_turn");

      //     pcConfig.iceServers[0].urls = urls;
      //     switch (stunTurnMode) {
      //       case "none":
      //         break;
      //       case "tcp":
      //       case "udp":
      //         urls = `${urls}?transport=${stunTurnMode}`;
      //         pcConfig.iceServers[0].urls = urls;
      //       default:
      //         options.pcConfig = pcConfig;
      //         break;
      //     }
      //   }
      // }

      // console.log("[jssipCall]:options=", options);
      var session = coolPhone.current.call(
        `sip:${to}@${process.env.REACT_APP_DOMAIN_NAME}`,
        options
      );
      session.myCallTo = to;
      session.isCallTransfer = isForTransfer;
      console.log("[jssipCall]:session=", session);
      if (isForTransfer) {
        sessionCallTransferRef.current = session;
      }
      return session;
    },
    [coolPhone, mySessionsId, mysessions, debugLogs]
  );

  useEffect(() => {
    console.log("[useEffect 1]: endpointid=", endpointid);
  }, [endpointid]);

  ///Register
  const handleRegister = useCallback(async () => {
    console.log("[handleRegister]:", { webrtc });

    const getEndpoint = await axiosi.post("users/getEndpoint");
    console.log({ getEndpoint });
    console.log("[handleRegister] : endpointid=", endpointid);
    //var socket = new JsSIP.WebSocketInterface("ws://192.168.1.99:8088/ws");
    var socket = new JsSIP.WebSocketInterface(
      `wss://${process.env.REACT_APP_DOMAIN_NAME}/ws`
    );

    const configuration = {
      sockets: [socket],
      //uri: `sip:${sipUser}@192.168.1.99`,
      //password: sipUser,

      // uri: `sip:${sipUser}@${process.env.REACT_APP_DOMAIN_NAME}`,
      // password: `${sipUser}1212`,
      uri: `sip:${endpointid}@${process.env.REACT_APP_DOMAIN_NAME}`,
      password: getEndpoint.data[0].password,
    };
    // console.log("[handleRegister]:configuration=", configuration);
    // console.log("[handleRegister]:configuration=", JSON.stringify(configuration));
    coolPhone.current = new JsSIP.UA(configuration);
    coolPhone.current.start();
    dispatch(registerMakeCall(jssipCall));

    coolPhone.current.on("connected", function (e) {
      /* Your code here */
      console.log("connected", e);
      // document.getElementById("txtConnectStatus").innerHTML = "connected";
      // elog(e);
      // debugger;
    });

    coolPhone.current.on("disconnected", function (e) {
      /* Your code here */
      console.log("disconnected", e);
      // document.getElementById("txtConnectStatus").innerHTML = "disconnected";
      // elog(e);
      // debugger;
    });

    coolPhone.current.on("registered", function (e) {
      /* Your code here */
      //handleFaviIcon("faviconGreenUser.ico");
      console.log("registered", e);
      dispatch(setRegisterStatus({ isRegister: true }));
      // document.getElementById(
      //   "txtRegisterStatus"
      // ).innerHTML = `[${e.response.from.uri.user}] : registered`;
      // console.log("registered", e);
      // debugger;
    });
    coolPhone.current.on("unregistered", function (e) {
      /* Your code here */
      // document.getElementById("txtRegisterStatus").innerHTML = "unregistered";
      // handleFaviIcon("faviconBlackUser.ico");
      console.log("unregistered", e);
      dispatch(setRegisterStatus({ isRegister: false }));
      // debugger;
    });
    coolPhone.current.on("registrationFailed", function (e) {
      /* Your code here */
      console.log("registrationFailed", e);
      // document.getElementById("txtRegisterStatus").innerHTML = "registrationFailed";
      // debugger;
      saveDebugLog({
        type: "registrationFailed",
        cause: e?.cause,
      });
    });

    function addRemoteVideo(id, stream) {
      console.log("addRemoteVideo :", id, stream);

      let remotevideo = document.getElementById("video" + id);
      console.log("remotevideo", remotevideo);
      debugger;
      if (!remotevideo) {
        remotevideo = window.document.createElement("video");
        remotevideo.id = "video" + id;
        const videoGrid = document.getElementById("video-grid");
        videoGrid.append(remotevideo);
      }
      remotevideo.srcObject = stream;
      remotevideo.addEventListener("loadedmetadata", () => {
        remotevideo.play();
      });
    }
    function adjustSDP(sdp) {
      // Split SDP into lines for processing
      const lines = sdp.split("\r\n");
      const preferredCodecs = ["8", "0"]; // G711A (8) ưu tiên trước, G711U (0) sau
      const audioLinePrefix = "m=audio";
      let modified = false;

      const newSDPLines = lines.map((line) => {
        if (line.startsWith(audioLinePrefix)) {
          // Extract current codecs from the m=audio line
          const parts = line.split(" ");
          const mediaType = parts[0];
          const port = parts[1];
          const protocol = parts[2];
          const codecs = parts.slice(3);

          // Reorder codecs based on preference
          const reorderedCodecs = [
            ...preferredCodecs.filter((codec) => codecs.includes(codec)),
            ...codecs.filter((codec) => !preferredCodecs.includes(codec)),
          ];

          // Reconstruct the m=audio line
          const newLine = `${mediaType} ${port} ${protocol} ${reorderedCodecs.join(
            " "
          )}`;
          modified = true;
          return newLine;
        }
        return line; // Keep all other lines unchanged
      });

      // If no m=audio line is found, return original SDP
      if (!modified) {
        return sdp;
      }

      // Join lines back into a single SDP string
      return newSDPLines.join("\r\n");
    }

    // coolPhone.current.on("newMessage", function (e) {
    //   const messageBody = e.request.body; // Nội dung tin nhắn
    //   const from = e.request.from.uri; // Địa chỉ người gửi
    //   const to = e.request.to.uri._user; // Địa chỉ người nhận

    //   console.log("...............newMessage..............");
    //   console.log("originator=", e.originator);
    //   console.log("message=", e.message);
    //   console.log("message.body=", e.message.body);
    //   console.log("request=", e.request);
    //   console.log("request.body=", e.request.body);
    //   console.log("request.data=", e.request.data);
    //   console.log("from=", from);
    //   console.log("from._user=", from._user);
    //   console.log("to=", to);
    //   console.log(e);
    // });
    coolPhone.current.on("newMessage", function (e) {
        console.log("...............newMessage..............");
      const messageBody = e.request.body; // Nội dung tin nhắn
      console.log("message=",JSON.parse(messageBody));
      const from = e.request.from.uri._user; // Địa chỉ người gửi
      if ((e.originator==="remote") && (from==="svc")){
        showSendNotification(messageBody)
      }

     
    });
    coolPhone.current.on("newRTCSession", function (e) {
      e.session.on("sdp", function (sepEvent_data) {
        let sdp = sepEvent_data.sdp;
        try {
          sdp = adjustSDP(sdp);
        } catch (error) {
          console.error("Error adjusting SDP:", error);
        }
        if (e.session.myIsHold) {
          e.session.myIsHold = false;
          sdp += "a=inactive";
          sdp += "\r\n";
          // console.log("[sdp]: ", sdp);
          sepEvent_data.sdp = sdp;
        } else {
          e.session.myUnHold = false;
          sdp += "a=sendrecv";
          sdp += "\r\n";
          // console.log("[sdp]: ", sdp);
          sepEvent_data.sdp = sdp;
        }
      });

      if (
        Object.keys(mysessionsInfo.current).length > 0 &&
        e.session.isCallTransfer == false
      ) {
        e.session.terminate();
        console.log("Terminate be cause , there is another call");
        // audioPlayers.Ring.pause();
        stopRingTone();
        saveDebugLog({
          tel: e.request.from.uri.user,
          type: "Terminate be cause , there is another call",
        });
        return;
      }

      mysessionsInfo.current[e.session.id] = {};
      mysessionsInfo.current[e.session.id].id = e.session.id;
      mysessionsInfo.current[e.session.id].display_name =
        e.request.from._display_name;
      mysessions.current[e.session.id] = e.session;

      if (e.originator === "remote") {
        //incoming call
        handleFaviIcon("favicon_ring.ico");
        const id = e.session.id;

        if (pauseStatusRef.current) {
          e.session.terminate();

          stopRingTone();
          saveDebugLog({
            tel: e.request.from.uri.user,
            callstatus: "da stop Ring vi dang  paused",
          });
          delete mysessionsInfo.current[e.session.id];
          delete mysessions.current[e.session.id];
          setMySessionsId((prev) => prev.filter((id) => id !== e.session.id));
          setRefreshCallLog((pre) => !pre);

          return;
        }

        try {
          onClickSendNotification(e.request.from.uri.user);
          saveDebugLog({
            tell: e.request.from.uri.user,
            callstatus: "da send Notification",
          });
        } catch (ex) {
          console.log("onClickSendNotification Error :", ex.message);
          saveDebugLog({
            tell: e.request.from.uri.user,
            callstatus: "bi loi khi  send Notification",
            error: ex.message,
          });
        }
        mysessionsInfo.current[id].direction = "in";
        mysessionsInfo.current[id].tel = e.request.from.uri.user;
        mysessionsInfo.current[id].uniqueid = e.request.headers.Uniqueid;
        mysessionsInfo.current[id].recordpath = e.request.headers.Recordpath;
        mysessionsInfo.current[id].calltype = e.request.headers.Calltype;
        setMySessionsId((prev) => [...prev, e.session.id]);

        playRingTone();
        saveDebugLog({
          tell: e.request.from.uri.user,
          callstatus: "da play Ring",
        });
        // mysessions.current[id].status = "Ringing...";
        mysessions.current[e.session.id].on("ended", (data) => {
          console.log(
            "[appjs-newRTCSession-remote]:The call is answered, and now is Endcall,data=",
            data
          );

          // audioPlayers.Ring.pause();
          // stopRingTone();
          saveDebugLog({
            tell: e.request.from.uri.user,
            callstatus: "[ended]",
            originator: data.originator,
          });
          addDebugLog({
            tell: e.request.from.uri.user,
            callstatus: "[ended]",
            originator: data.originator,
          });

          delete mysessionsInfo.current[e.session.id];
          delete mysessions.current[e.session.id];

          setMySessionsId((prev) => prev.filter((id) => id !== e.session.id));
          setRefreshCallLog((pre) => !pre);
          // debugger;
          if (data.originator === "remote") {
            console.log("[eventHandlers-failed_]:remote-> playEND");
            playEndTone();

            saveDebugLog({
              tell: e.request.from.uri.user,
              callstatus: "[ended - remote] : dang audioPlayers.End.Play",
            });
          }
          handleStopRecording();
          saveDebugLogEnd();
        });
        mysessions.current[e.session.id].on(
          "progress",
          (session_event_data) => {
            saveDebugLog({
              tell: e.request.from.uri.user,
              callstatus: "[progress]",
              data: session_event_data,
            });
          }
        );
        mysessions.current[e.session.id].on(
          "accepted",
          (session_event_data) => {
            saveDebugLog({
              tell: e.request.from.uri.user,
              callstatus: "[accepted]",
              data: session_event_data,
            });
          }
        );
        mysessions.current[e.session.id].on(
          "confirmed",
          (session_event_data) => {
            saveDebugLog({
              tell: e.request.from.uri.user,
              callstatus: "[confirmed]",
              originator: session_event_data.originator,
            });
          }
        );
        ////////////////
        mysessions.current[e.session.id].on(
          "icecandidate",
          (session_event_data) => {
            saveDebugLog({
              tell: e.request.from.uri.user,
              call_status: "incall icecandidate",
              data: session_event_data?.candidate?.candidate,
            });
            // console.log("makeCall icecandidate data.candidate.candidate \n\r", data?.candidate?.candidate);
          }
        );
        mysessions.current[e.session.id].on(
          "getusermediafailed",
          function (callEventData) {
            saveDebugLog({
              tell: e.request.from.uri.user,
              call_status: "incall getusermediafailed",
              data: callEventData,
            });
          }
        );
        ///////////////
        mysessions.current[e.session.id].on("failed", (data) => {
          stopRingTone();

          addDebugLog({
            tell: e.request.from.uri.user,
            callstatus: "[failed]: da stopRingTone",
            originator: data.originator,
          });

          delete mysessionsInfo.current[e.session.id];
          delete mysessions.current[e.session.id];

          setMySessionsId((prev) => prev.filter((id) => id !== e.session.id));
          console.log("Need Rerender");
          setRefreshCallLog((pre) => !pre);
          if (data.originator === "remote") {
            playEndTone();
            saveDebugLog({
              tell: e.request.from.uri.user,
              callstatus: "[failed,remote] :da playEndTone() ",
            });
          }
          saveDebugLog({
            tell: e.request.from.uri.user,
            callstatus: "[failed] : da stopRingTone",
            originator: data.originator,
          });
          handleStopRecording();
          saveDebugLogEnd();
        });
        mysessions.current[e.session.id].on("peerconnection", (data) => {
          data.peerconnection.addEventListener("track", (e1) => {
            playRemoteAudio(
              e1.streams[0],
              mysessionsInfo.current[e.session.id].tel
            );
          });
        });
        // mysessions.current[e.session.id].connection.addEventListener(
        //   "track",
        //   (e1) => {
        //     playRemoteAudio(
        //       e1.streams[0],
        //       mysessionsInfo.current[e.session.id].tel
        //     );
        //   }
        // );
      } else if (e.originator === "local") {
        //outgoing call
        const id = e.session.id;
        // mysessions.current[id].direction = "out";
        // mysessions.current[id].tel = e.request.from.uri.user;
        // mysessions.current[id].status = "local";

        mysessionsInfo.current[e.session.id].caller = e.request.from._uri._user;

        let telsdt = e.request.ruri._user;
        try {
          if (telsdt.length > 0) {
            if (telsdt[0] === "b") {
              telsdt = telsdt.substring(1);
            }
          }
        } catch (e) {}
        mysessionsInfo.current[e.session.id].callee = telsdt;
        mysessionsInfo.current[e.session.id].tel = telsdt;
        mysessionsInfo.current[id].direction = "out";
        mysessionsInfo.current[id].call_id = e.request.call_id;

        e.session.connection.oniceconnectionstatechange = () => {
          console.log(
            "[oniceconnectionstatechange]:ICE connection state:",
            e.session.connection.iceConnectionState
          );
          saveDebugLog({
            type: "oniceconnectionstatechange",
            iceConnectionState: e.session.connection.iceConnectionState,
          });
          if (e.session?.connection?.iceConnectionState === "connected") {
            console.log(
              "[oniceconnectionstatechange]:ICE connection completed"
            );
          }
        };
        e.session.connection.onicegatheringstatechange = () => {
          console.log(
            "[onicegatheringstatechange]:ICE gathering state:",
            e.session.connection.iceGatheringState
          );
          saveDebugLog({
            type: "onicegatheringstatechange",
            iceGatheringState: e.session.connection.iceGatheringState,
          });
          if (e.session?.connection?.iceGatheringState === "complete") {
            console.log("[onicegatheringstatechange]:ICE gathering completed");
          }
        };
        setMySessionsId((prev) => [...prev, e.session.id]);

        mysessions.current[e.session.id].connection.addEventListener(
          "track",
          (e1) => {
            if (mysessions.current[e.session.id].isCallTransfer) {
              playTransferAudio(
                e1.streams[0],
                mysessionsInfo.current[e.session.id].tel
              );
            } else {
              playRemoteAudio(
                e1.streams[0],
                mysessionsInfo.current[e.session.id].tel
              );
            }
          }
        );

        mysessions.current[e.session.id].on("failed", async (data) => {
          const causefromphone =
            data?.message?.reason_phrase + " | " + data.cause;
          const call_id = data?.message?.call_id;
          message.info({
            content: `${causefromphone}`,
            className: "messageRight",
          });
          console.log("[updatephonecauseToCDR]:", { causefromphone, call_id });
          try {
            const kqupdatephonecauseToCDR = await axiosi.put(
              "calllogs/updatephonecauseToCDR",
              {
                causefromphone,
                call_id,
              }
            );
          } catch {}
        });

        mysessions.current[e.session.id].on("confirmed", (data) => {
          console.log(
            "[App.js - mysessions.current[e.session.id].on = confirmed]:data=",
            data
          );
        });
      }
      // debugger;
    });
  }, [
    coolPhone,
    pauseStatus,
    dispatch,
    jssipCall,
    audioPlayers.Ring,
    endpointid,
    axiosi,
    debugLogs,
  ]);

  const handleUnregister = () => {
    // coolPhone.current.unregister();
    if (coolPhone.current) {
      coolPhone.current.stop();
    }
  };
  const handleReregister = () => {
    // coolPhone.current.register();
    coolPhone.current.start();
  };

  const doRefreshRegister = () => {
    setTimeout(() => {
      setRefreshRegister((prev) => !prev);
    }, 60000);
  };
  useEffect(() => {
    setRingSpeaker();
  }, []);
  useEffect(() => {
    try {
      coolPhone.current?.register();
    } catch (error) {
      console.log("reisterError =", error.message);
    }
    doRefreshRegister();
  }, [refreshRegister]);
  // useEffect(() => {
  //   // if (isLogined) {
  //   //   handleRegister();
  //   // }

  //   console.log("[0 useEffect_getpassword]::::::::::::::::ALO");
  //   async function getpassword() {
  //     if (isLogined && endpointid !== "") {
  //       const kq = await axiosi.post("users/getEndpoint");
  //       // const kq = await getEndpoint();
  //       console.log("[useEffect_getpassword]", kq);

  //       handleRegister();
  //     }
  //   }
  //   console.log("[0 useEffect_getpassword]");
  //   getpassword();
  // }, [isLogined, handleRegister, axiosi, endpointid]);

  useEffect(() => {
    // if (user?.agents[0]["webrtc"] != "yes") {
    //   return;
    // }
    console.log("[---------useEffect-----------]:", {
      isLogined,
      endpointid,
      webrtc,
    });
    if (isLogined && endpointid !== "" && webrtc === "yes") {
      console.log("[---------useEffect-----------]:will Register");
      handleRegister();
    } else {
      console.log("[---------useEffect-----------]:WILL unRegister");
      handleUnregister();
    }

    // console.log("[0 useEffect_getpassword]::::::::::::::::ALO");
    // async function getpassword() {
    //   if (isLogined && endpointid !== "") {
    //     const kq = await axiosi.post("users/getEndpoint");
    //     // const kq = await getEndpoint();
    //     console.log("[useEffect_getpassword]", kq);

    //     handleRegister();
    //   }
    // }
    // console.log("[0 useEffect_getpassword]");
    // getpassword();
  }, [isLogined, endpointid, webrtc]);

  useEffect(() => {
    pauseStatusRef.current = pauseStatus;
  }, [pauseStatus]);
  useEffect(() => {
    const getPause = async () => {
      try {
        const kq = await axiosi.get(`queue_members/pause`, {
          params: {
            paused: 1,
            membername: user?.agents[0].endpoint_id,
            company_id: user?.agents[0].company_id,
          },
        });
        console.log("[getPause]:kq=", kq);
        if (kq.data != "") {
          if (kq.data.rows.length > 0) {
            setPauseStatus(true);
          } else {
            setPauseStatus(false);
          }
        } else {
          setPauseStatus(false);
        }
      } catch (error) {
        console.log("[getPause]:error");
      }
    };
    if (isLogined && user?.agents && user?.agents[0].endpoint_id !== "") {
      getPause();
    }
  }, [isLogined, user?.agents]);

  useEffect(() => {
    handleFaviIcon("faviconGreenUser.ico");
    if (!isRegister) {
      setDisplayRegisterStatus(" is not Registered");
      setUserAvatar("userUnRegistered");
      handleFaviIcon("faviconBlackUser.ico");
    } else if (pauseStatus) {
      setDisplayRegisterStatus(" is Registered and Paused");
      setUserAvatar("userPause");
    } else {
      setDisplayRegisterStatus(" is Registered");
      setUserAvatar("userRegistered");
    }
  }, [isRegister, pauseStatus]);

  useEffect(() => {
    if (!isLogined) {
      handleUnregister();
    }
  }, [isLogined]);

  if (!isLogined) {
    return <Login />;
  }
  const toggle = () => {
    setCollapsed((prev) => !prev);
  };
  const handleLogout = () => {
    setHideItem("hideItem");

    axios.post(
      `${process.env.REACT_APP_DOMAIN}/users/logout`,
      { user },
      {
        withCredentials: true,
      }
    );

    localStorage.removeItem(process.env.REACT_APP_LSTOKEN);
    dispatch(
      loginSlice.actions.setLogin({
        isLogined: checkLoginStatusInLocalStorage(),
      })
    );
    dispatch(loginSlice.actions.setWebrtc({ webrtc: "KKKKK" }));
    coolPhone.current.unregister({ all: true });
    coolPhone.current.stop();
    axiosi.delete("pscontacts/mycontact");
  };

  const handleGetEndpoint = async () => {
    const kq = await axiosi.post("users/getEndpoint");
    console.log(kq.data);
  };

  const handleGetSubMenu = () => {
    if (user.agents) {
      let curpathname = location.pathname;
      const curpathnameArr = curpathname.split("/");
      curpathname = "/" + curpathnameArr[1];
      const menuitem = user.menu.menu_items;
      const item = menuitem.find((i) => i.item_key === curpathname);

      if (item) {
        console.log("[handleGetSubMenu]:Tim` thay item", curpathname);
        const menusubs = user.menu.menu_subs;
        const sub = menusubs.find((s) => s.id === item.menu_sub_id);
        console.log("sub=", sub);
        setSelectSubMenu(sub.menukey);
        console.log("sub.menukey=", sub.menukey);
      } else {
        console.log("[handleGetSubMenu]:Kg tim thay Item", curpathname);
      }
    } else {
      console.log("[handleGetSubMenu]:kg co' agents");
    }
  };

  const displayExt = (endpointid) => {
    const ext = endpointid.split("_");
    return ext[0];
  };
  // handleGetSubMenu();
  const checkRoute = (menuitem) => {
    if (user?.menu?.menu_items.find((item) => item.item_key === menuitem)) {
      return true;
    }
    return false;
  };
  //////////////
  const getUniqueid = async (id, call_id) => {
    try {
      const kq = await axiosi.get("callstatus/cdrout", { params: { call_id } });
      const kqdata = await kq.data;
      // console.log("[getCustomerInfoByTel]:kqdata=",kqdata);
      // console.log("[getCustomerInfoByTel]:kqdata.rows=",kqdata.rows);

      if (kqdata.rows.length > 0) {
        mysessionsInfo.current[id].uniqueid = kqdata.rows[0]["uniqueid"];
        mysessionsInfo.current[id].recordpath = kqdata.rows[0]["recordpath"];
      } else {
        mysessionsInfo.current[id].uniqueid = "";
        mysessionsInfo.current[id].recordpath = "";
      }
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log("error.response.data=", error.response.data);
        console.log("error.response.status=", error.response.status);
        console.log("error.response.headers=", error.response.headers);
        // message.error(`Error: ${error.response.data.code}`);
        mysessionsInfo.current[id].uniqueid = "";
        mysessionsInfo.current[id].recordpath = "";
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
        mysessionsInfo.current[id].uniqueid = "";
        mysessionsInfo.current[id].recordpath = "";
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
        mysessionsInfo.current[id].uniqueid = "";
        mysessionsInfo.current[id].recordpath = "";
      }
      console.log(error.config);
      mysessionsInfo.current[id].uniqueid = "";
      mysessionsInfo.current[id].recordpath = "";
    }
  };

  const handleShowAudioDevices = async () => {
    setHideItem("hideItem");
    setVisibleAudioDevices(true);
  };
  const handleShowSTUNTURN = async () => {
    setHideItem("hideItem");
    setVisibleStunTurn(true);
  };
  const handlePause = async () => {
    console.log("[handPause]");
    setHideItem("hideItem");
    setVisiblePause(true);
  };
  const handleunPause = async () => {
    console.log("[handleunPause]");
    setHideItem("hideItem");
    try {
      const kq = await axiosi.put(`queue_members/pause`, {
        reason: "",
        paused: 0,
        membername: user?.agents[0].endpoint_id,
        company_id: user?.agents[0].company_id,
      });
      setPauseStatus(false);
    } catch (error) {
      console.loge("[handleunPause]:error");
    }
  };
  const handleChangeProfile = async () => {
    setHideItem("hideItem");
    console.log("[handleChangeProfile]");
    setVisibleProfile(true);
  };

  const onClosePause = () => {
    setVisiblePause(false);
  };
  const onCloseProfile = () => {
    setVisibleProfile(false);
  };
  /////////////

  const showHideProfileMenu = () => {
    setHideItem((pre) => (pre === "" ? "hideItem" : ""));
  };
  // Hàm để xin quyền truy cập microphone
  const requestMicrophonePermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      console.log("Microphone permission granted");
      alert("Microphone is allowed!");
      // Notification.requestPermission().then((result) => {
      //   console.log(result);
      //   if (result === 'granted') {
      //     console.log("granted");
      //     new Notification("Microphone is allowed!")
      //   } else {
      //     console.log("not granted");
      //   }
      // });
    } catch (error) {
      console.error("Microphone permission denied", error);
      alert("Microphone permission denied");
    }
  };

  // Hàm để xin quyền Notification
  const requestNotificationPermission = async () => {
    if (!("Notification" in window)) {
      console.error("This browser does not support notifications.");
      return;
    }

    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      console.log("Notification permission granted");
      const tb = new Notification("Notification is allowed");
      tb.onclick = (event) => {
        console.log("click3");

        if (window.focus) {
          window.focus();
        }
      };
    } else {
      console.error("Notification permission denied");
      alert("Notification permission denied");
    }
  };

  const playRingTone = async () => {
    if (ringToneRef.current) {
      ringToneRef.current.currentTime = 0;
      ringToneRef.current.play().catch((err) => {
        console.log("[ringToneRef]: Error playing audio: " + err.message);
      });
    }
  };
  const stopRingTone = () => {
    if (ringToneRef.current) {
      ringToneRef.current.pause();
      // ringToneRef.current.currentTime = 0;
    }
  };
  const playEndTone = () => {
    if (endToneRef.current) {
      endToneRef.current.currentTime = 0;
      endToneRef.current.play().catch((err) => {
        console.log("[endToneRef]: Error playing audio: " + err.message);
      });
    }
  };
  const playRemoteAudioTest = async () => {
    try {
      if (remoteAudioRef.current) {
        console.log("[playRemoteAudioTest]: Begin");
        // const talkSpeaker = localStorage.getItem("talkSpeaker") || "";

        remoteAudioRef.current.currentTime = 0;
        const audioUrl = "./media/doremon.mp3";

        remoteAudioRef.current.srcObject = null;
        remoteAudioRef.current.src = audioUrl;

        // await remoteAudioRef.current.setSinkId(talkSpeaker);
        // console.log("[playRemoteAudioTest]: setSinkId TalkSpeaker=",talkSpeaker);
        addDebugLogMessage(`[playRemoteAudioTest]: Begin play ${audioUrl}`);
        remoteAudioRef.current.play().catch((err) => {
          console.log("[remoteAudioRef]: Error playing audio: " + err.message);
        });
      }
    } catch (err) {
      console.log("[playRemoteAudioTest]: err=", err.message);
    }
  };

  const playRemoteAudio = (sourceAudio, tel) => {
    addDebugLogMessage(`[playRemoteAudio]: Begin ${tel}`);
    if (remoteAudioRef.current) {
      remoteAudioRef.current.srcObject = null;
      remoteAudioRef.current.srcObject = sourceAudio;
      remoteAudioRef.current.currentTime = 0;
      remoteAudioRef.current.play().catch((err) => {
        console.log("[remoteAudioRef]: Error playing audio: " + err.message);
        addDebugLogMessage(
          `[playRemoteAudio]: Error playing audio: ` + err.message
        );
      });
      handleRecordAudio(sourceAudio, tel);
    } else {
      addDebugLogMessage(`[playRemoteAudio]: remoteAudioRef.current is null`);
    }
  };
  const stopRemoteAudio = () => {
    addDebugLogMessage(`[stopRemoteAudio]: Begin stop`);
    if (remoteAudioRef.current) {
      remoteAudioRef.current.pause();
    }
  };

  const playTransferAudio = (sourceAudio, tel = "_") => {
    addDebugLogMessage(`[playTransferAudio]: Begin ${tel}`);
    if (transferAudioRef.current) {
      addDebugLogMessage(`[playTransferAudio]: Begin ${tel} Real`);
      transferAudioRef.current.srcObject = sourceAudio;
      transferAudioRef.current.currentTime = 0;
      transferAudioRef.current.play().catch((err) => {
        console.log("[transferAudioRef]: Error playing audio: " + err.message);
      });
    }
  };
  const stopTransferAudio = () => {
    if (transferAudioRef.current) {
      transferAudioRef.current.pause();
    }
  };
  return (
    <>
      {/* {console.log("[App-Return]:user=", user)}
      {console.log("[App-Return]:webrtc=", webrtc)} */}
      <Drawer
        title="STUN/TURN"
        placement="right"
        onClose={() => setVisibleStunTurn(false)}
        visible={visibleStunTurn}
      >
        <StunTurn setVisible={setVisibleStunTurn} />
      </Drawer>
      <Drawer
        title="Audio Devices"
        placement="right"
        onClose={() => setVisibleAudioDevices(false)}
        visible={visibleAudioDevices}
      >
        <AudioDevices
          setVisible={setVisibleAudioDevices}
          setRingSpeaker={setRingSpeaker}
          playRingTone={playRingTone}
          stopRingTone={stopRingTone}
          playRemoteAudioTest={playRemoteAudioTest}
          stopRemoteAudio={stopRemoteAudio}
          ringToneDevice={ringToneRef.current}
          remoteAudioDevice={remoteAudioRef.current}
        />
      </Drawer>
      <Drawer
        title="Pause"
        placement="right"
        onClose={onClosePause}
        visible={visiblePause}
      >
        <Pause
          setVisible={setVisiblePause}
          setPauseStatus={setPauseStatus}
          setPauseReason={setPauseReason}
        />
      </Drawer>
      <Drawer
        title="Profile"
        placement="right"
        onClose={onCloseProfile}
        visible={visibleProfile}
      >
        <Profile setVisible={setVisibleProfile} />
      </Drawer>
      <Layout>
        <Header theme="dark" className={"myHeader"}>
          <div className="myHeaderLeft">
            <Title theme="dark" level={3} style={{ color: "white" }}>
              <a className="linkGM" href="" target="_blank">
                <HomeOutlined /> <span>Call-Center</span>{" "}
              </a>
            </Title>
          </div>
          <div className="myHeaderRight">
            <Avatar
              className={userAvatar}
              style={{ cursor: "pointer" }}
              icon={<UserOutlined />}
              title="Click to show/hide Profile Menu !"
              onClick={showHideProfileMenu}
            />
            <span style={{ color: "white" }}>
              {user?.agents && user.agents[0].name}
            </span>
            <ul className={`userMenu ${hideItem}`}>
              {!pauseStatus && (
                <li className="userMenu_item" onClick={handlePause}>
                  Pause
                </li>
              )}
              {pauseStatus && (
                <li className="userMenu_item" onClick={handleunPause}>
                  unPause
                </li>
              )}
              <li className="userMenu_item" onClick={handleChangeProfile}>
                Change profile
              </li>

              <li
                className="userMenu_item"
                onClick={requestNotificationPermission}
              >
                Notification permission
              </li>

              <li
                className="userMenu_item"
                onClick={requestMicrophonePermission}
              >
                Microphone permission
              </li>

              <li className="userMenu_item" onClick={handleShowAudioDevices}>
                Audio Devices
              </li>
              {showSTUNTURNMenu && (
                <li className="userMenu_item" onClick={handleShowSTUNTURN}>
                  STUN/TURN Settings
                </li>
              )}

              <li className="userMenu_item" onClick={handleLogout}>
                Logout
              </li>
            </ul>

            {/* <Button type="link" size={"large"}>
              {user?.agents ? `Hi ${user.agents[0].name}` : ""}
              <span onClick={handleLogout}>[Pause]</span>
              <span onClick={handleLogout} title="Logout">
                <LogoutOutlined />
              </span>
            </Button> */}
          </div>
        </Header>
        {/* <Header theme="dark" className={"myHeader"}>
          <div className="myHeaderLeft">
            <Title theme="dark" level={3} style={{ color: "white" }}>
              Call Center
            </Title>
          </div>
          <div className="myHeaderRight">
            <Button type="link" size={"large"}>
              {user?.agents ? `Hi ${user.agents[0].name}` : ""}
              <br /> <span onClick={handleLogout}>[Logout]</span>
            </Button>
          </div>
        </Header> */}
        <Layout>
          <Sider
            collapsed={collapsed}
            width={200}
            className="site-layout-background"
            onCollapse={toggle}
            collapsible
          >
            {user?.agents ? <MyMenu /> : <span></span>}
          </Sider>
          <Layout style={{ padding: "0 24px 24px" }}>
            <div className="Phone">
              <div className="PhoneStatus colFlex">
                <label>
                  Register Status : {displayExt(endpointid)}
                  {displayRegisterStatus}
                  {/* {isRegister ? " is Registered" : " is not Registered"} */}
                </label>
                <GetIP />
              </div>
              <div className="PhoneQuickCall">
                <QuickCall />
              </div>
            </div>
            <div className="rowFlex" style={{ gap: "10px", display: "none" }}>
              <div className="colFlex">
                <span>RingTone : {ringToneTime}</span>
                <audio ref={ringToneRef} preload="auto" controls loop>
                  <source
                    src={"./media/ring_mono60s.mp3?v=1"}
                    type="audio/mpeg"
                  />
                </audio>
                <div>
                  <button onClick={playRingTone}>Play</button>
                  <button onClick={stopRingTone}>Stop</button>
                </div>
              </div>

              <div className="colFlex">
                <span>EndTone :</span>
                <audio ref={endToneRef} preload="auto" controls>
                  <source
                    src={"./media/Tone_Congestion-US.mp3"}
                    type="audio/mpeg"
                  />
                </audio>
                <div>
                  <button onClick={playEndTone}>Play</button>
                </div>
              </div>
              <div className="colFlex">
                <span>Remote Audio :</span>
                <audio ref={remoteAudioRef} controls>
                  {/* <source ref={"./media/svc_tb_tc.mp3"} type="audio/mpeg" /> */}
                </audio>
                <div>
                  <button onClick={playRemoteAudioTest}>PlayTest</button>
                </div>
                <div>
                  <button onClick={stopRemoteAudio}>Stop</button>
                </div>
              </div>
              <div className="colFlex">
                <span>Transfer Audio :</span>
                <audio ref={transferAudioRef} controls></audio>
              </div>
            </div>

            <Content
              className="site-layout-background"
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
              }}
            >
              <Routes>
                <Route path="/" element={<Home />} />

                {/* <Route
                  path="/"
                  element={<CallLog refreshCallLog={refreshCallLog} />}
                >
                  <Route
                    path=":page"
                    element={<CallLog refreshCallLog={refreshCallLog} />}
                  />
                </Route> */}
                {checkRoute("/dashboard") && (
                  <Route path="/dashboard" element={<Dashboard />}></Route>
                )}
                {/* <Route path="/calllog" element={<CallLog />}></Route> */}

                {checkRoute("/calllog") && (
                  <Route
                    path="/calllog"
                    element={<CallLog refreshCallLog={refreshCallLog} />}
                  >
                    <Route
                      path=":page"
                      element={<CallLog refreshCallLog={refreshCallLog} />}
                    />
                  </Route>
                )}

                {checkRoute("/currentcall") && (
                  <Route path="/currentcall" element={<CurrentCall />}>
                    <Route path=":page" element={<CurrentCall />} />
                  </Route>
                )}

                {/* <Route path="/myphone" element={<MyPhone />}></Route>
                <Route path="/callout" element={<CallOut />}></Route> */}

                {checkRoute("/menu_item") && (
                  <Route path="/menu_item" element={<MenuItem />}>
                    <Route path=":page" element={<MenuItem />} />
                  </Route>
                )}
                {checkRoute("/menu_sub") && (
                  <Route path="/menu_sub" element={<MenuSub />}>
                    <Route path=":page" element={<MenuSub />} />
                  </Route>
                )}
                {checkRoute("/authorization_group") && (
                  <Route
                    path="/authorization_group"
                    element={<AuthorizationGroup />}
                  >
                    <Route path=":page" element={<AuthorizationGroup />} />
                  </Route>
                )}
                {checkRoute("/authorization_group_item") && (
                  <Route
                    path="/authorization_group_item"
                    element={<AuthorizationGroupItem />}
                  >
                    <Route path=":page" element={<AuthorizationGroupItem />} />
                  </Route>
                )}

                {checkRoute("/itsps") && (
                  <Route path="/itsps" element={<Itsps />}>
                    <Route path=":page" element={<Itsps />} />
                  </Route>
                )}

                {checkRoute("/companies") && (
                  <Route path="/companies" element={<Companies />}>
                    <Route path=":page" element={<Companies />} />
                  </Route>
                )}

                {checkRoute("/tels") && (
                  <Route path="/tels" element={<Tels />}>
                    <Route path=":page" element={<Tels />} />
                  </Route>
                )}

                {checkRoute("/tels_callout_rule") && (
                  <Route path="/tels_callout_rule" element={<CalloutRule />}>
                    <Route path=":page" element={<CalloutRule />} />
                  </Route>
                )}

                {checkRoute("/agents") && (
                  <Route path="/agents" element={<Agents />}>
                    <Route path=":page" element={<Agents />} />
                  </Route>
                )}
                {checkRoute("/blacklist") && (
                  <Route path="/blacklist" element={<BlackList />}>
                    <Route path=":page" element={<BlackList />} />
                  </Route>
                )}
                {checkRoute("/surveyplan") && (
                  <Route path="/surveyplan" element={<SurveyPlan />}>
                    <Route path=":page" element={<SurveyPlan />} />
                  </Route>
                )}
                {checkRoute("/department") && (
                  <Route path="/department" element={<Department />}>
                    <Route path=":page" element={<Department />} />
                  </Route>
                )}

                {checkRoute("/ivrplanmain") && (
                  <Route path="/ivrplanmain" element={<IVRPlanMain />}>
                    <Route path=":page" element={<IVRPlanMain />} />
                  </Route>
                )}

                {checkRoute("/ivrplanfordaily") && (
                  <Route path="/ivrplanfordaily" element={<IVRPlanForDaily />}>
                    <Route path=":page" element={<IVRPlanForDaily />} />
                  </Route>
                )}

                {checkRoute("/ivrplanforkey") && (
                  <Route path="/ivrplanforkey" element={<IVRPlanForKey />}>
                    <Route path=":page" element={<IVRPlanForKey />} />
                  </Route>
                )}

                {checkRoute("/ivrplanforweekday") && (
                  <Route
                    path="/ivrplanforweekday"
                    element={<IVRPlanForWeekday />}
                  >
                    <Route path=":page" element={<IVRPlanForWeekday />} />
                  </Route>
                )}

                {checkRoute("/ivrplanforday") && (
                  <Route path="/ivrplanforday" element={<IVRPlanForDay />}>
                    <Route path=":page" element={<IVRPlanForDay />} />
                  </Route>
                )}

                {checkRoute("/comcontacts") && (
                  <Route path="/comcontacts" element={<Contacts />}>
                    <Route path=":page" element={<Contacts />} />
                  </Route>
                )}

                {checkRoute("/outboundplan") && (
                  <Route path="/outboundplan" element={<OutboundPlan />}>
                    <Route path=":page" element={<OutboundPlan />} />
                  </Route>
                )}

                {checkRoute("/outbounddata") && (
                  <Route path="/outbounddata" element={<OutBoundData />}>
                    <Route path=":page" element={<OutBoundData />} />
                  </Route>
                )}

                {checkRoute("/outboundagentdata") && (
                  <Route
                    path="/outboundagentdata"
                    element={<AgentOutboundData />}
                  >
                    <Route path=":page" element={<AgentOutboundData />} />
                  </Route>
                )}

                {checkRoute("/znsplan") && (
                  <Route path="/znsplan" element={<ZnsPlan />}>
                    <Route path=":page" element={<ZnsPlan />} />
                  </Route>
                )}
                {checkRoute("/znsdata") && (
                  <Route path="/znsdata" element={<ZnsData />}>
                    <Route path=":page" element={<ZnsData />} />
                  </Route>
                )}

                {checkRoute("/send_sms") && (
                  <Route path="/send_sms" element={<SendSMS />}>
                    <Route path=":page" element={<SendSMS />} />
                  </Route>
                )}

                {checkRoute("/sms_tnth") && (
                  <Route path="/sms_tnth" element={<SMSThuongHieu />}>
                    <Route path=":page" element={<SMSThuongHieu />} />
                  </Route>
                )}

                {checkRoute("/tickets") && (
                  <Route path="/tickets" element={<Tickets />}>
                    <Route path=":page" element={<Tickets />} />
                  </Route>
                )}

                {checkRoute("/autocall") && (
                  <Route path="/autocall" element={<AutoCall />}>
                    <Route path=":page" element={<AutoCall />} />
                  </Route>
                )}

                {checkRoute("/surveydata") && (
                  <Route path="/surveydata" element={<SurveyData />}>
                    <Route path=":page" element={<SurveyData />} />
                  </Route>
                )}

                {checkRoute("/surveycurrentcall") && (
                  <Route
                    path="/surveycurrentcall"
                    element={<SurveyCurrentCall />}
                  >
                    <Route path=":page" element={<SurveyCurrentCall />} />
                  </Route>
                )}

                {checkRoute("/agentstatus") && (
                  <Route path="/agentstatus" element={<AgentStatus />}>
                    <Route path=":page" element={<AgentStatus />} />
                  </Route>
                )}

                {checkRoute("/agenteventsum") && (
                  <Route path="/agenteventsum" element={<AgentLogs />}>
                    <Route path=":page" element={<AgentLogs />} />
                  </Route>
                )}
                {checkRoute("/surveyac") && (
                  <Route path="/surveyac" element={<SurveyAfterCall />}>
                    <Route path=":page" element={<SurveyAfterCall />} />
                  </Route>
                )}
                
              </Routes>
            </Content>
          </Layout>
        </Layout>
      </Layout>
      <div className="App1">
        {/* <TicketNote provinceData={provinceData} /> */}
        {/* {console.log("[RETURN-APP]:mySessionsIdTicket=", mySessionsIdTicket)} */}
        {mySessionsIdTicket &&
          mySessionsIdTicket.map((id) => (
            <TicketNote
              key={id}
              id={id}
              // mysession={mysessions.current[id]}
              provinceData={provinceData}
              mysessionInfo={mysessionsInfo.current[id]}
              setMySessionsIdTicket={setMySessionsIdTicket}
            />
          ))}

        {/* <CallIn 
            key={"id"}
            id={"id"}
            mysession={{}}
            mysessionInfo={{}}
            setMySessionsIdTicket={setMySessionsIdTicket}
        /> */}
        {/* <CallOut /> */}
        {/* <div>
          <h1>Register</h1>
          <input value={sipUser} onChange={(e) => setSipUser(e.target.value)} />
          <button onClick={handleRegister}>Register</button>
        </div> */}
        {/* <MakeCall /> */}
        {/* {mySessionsId && console.log("mySessionId=", mySessionsId)} */}

        {mySessionsId &&
          mySessionsId.map((id) => {
            // console.log(
            //   "mysessionsInfo.current[id]=",
            //   mysessionsInfo.current[id]
            // );
            if (!(mysessionsInfo.current[id].isCallTransfer == true)) {
              if (mysessionsInfo.current[id].direction === "in") {
                return (
                  <CallIn
                    key={id}
                    id={id}
                    mysession={mysessions.current[id]}
                    mysessionInfo={mysessionsInfo.current[id]}
                    setMySessionsIdTicket={setMySessionsIdTicket}
                    stopRingTone={stopRingTone}
                    addDebugLog={addDebugLog}
                    addDebugLogMessage={addDebugLogMessage}
                    resetDebugLog={resetDebugLog}
                    ringToneTime={ringToneTime}
                    playRemoteAudio={playRemoteAudio}
                    remoteAudioTime={remoteAudioTime}
                  />
                );
              } else
                return (
                  <CallOut
                    key={id}
                    id={id}
                    mysession={mysessions.current[id]}
                    mysessionInfo={mysessionsInfo.current[id]}
                    setMySessionsIdTicket={setMySessionsIdTicket}
                    addDebugLog={addDebugLog}
                    addDebugLogMessage={addDebugLogMessage}
                    resetDebugLog={resetDebugLog}
                    remoteAudioTime={remoteAudioTime}
                  />
                );
            }
          })}
      </div>
    </>
  );
}
export default App;
