import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import axios from "axios";
import { Table, Tag, Space, Button, Typography, Drawer } from "antd";
import {
  StepForwardOutlined,
  DownloadOutlined,
  PhoneOutlined,
  UserOutlined,
  HomeOutlined,
  FlagOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  MailOutlined,
  InfoCircleOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from "@ant-design/icons";
import useAxios from "./../../../CustomHooks/useAxios";
const dateFormat = "YYYY-MM-DD HH:mm:ss";
const ShowTable = ({ data, setRefressSearch, sttBegin,sapxep,setSapxep }) => {
  let stt=(sttBegin)?sttBegin:1;
  const { axiosi } = useAxios();
  const user = useSelector((state) => state.loginReducer.user);
  // const [sapxep, setSapxep] = useState("DESC");
  return (
    <>
      <table className="table1">
          <thead>
            <tr>
              
              <th>No.</th>
              

              <th>Department</th>
              <th>IVR Key</th>
              <th>Quantity</th>
              
              
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr key={item.id}>
                
                <td>{stt++}</td>
                <td>{item.department_name}</td>
                <td>
                  
                {item.ivrkey}

                </td>
                
                
                <td>
                  {item.quantity}
                </td>
                
              </tr>
            ))}
          </tbody>
        </table>
    </>
  )
}

export default ShowTable