import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
// import { Routes, Route, Link, useLocation, useMatch } from "react-router-dom";
import moment from "moment";

import {
  Button,
  Row,
  Col,
  Divider,
  Typography,
  DatePicker,
  TimePicker,
  Select,
  Input,
  Space,
} from "antd";
// import axios from 'axios';
//https://www.sitepoint.com/managing-dates-times-using-moment-js/
import useAxios from "./../../CustomHooks/useAxios";
import ShowCallLogTable from "./ShowCallLogTable";
import PageLink from "./../PageLink/PageLink";
import PageLink1 from "./../PageLink/Page"; //PageLink';
import ShowSummary from "./ShowSummary";
import DebounceSelect from "./../DebounceSelect";
const { Text } = Typography;
// const { RangePicker } = DatePicker;
const { Option } = Select;
const dateFormat = "YYYY-MM-DD";
const timeFormat = "HH:mm";
const dateFormat1 = "YYYY-MM-DD HH:mm";

export default function CallLog({ refreshCallLog }) {
  // let { page } = useParams();
  const [pickupSecond, setPickupSecond] = useState(process.env.REACT_APP_PICKUP_SECOND);
  const [page, setPage] = useState();
  const { axiosi } = useAxios();

  const user = useSelector((state) => state.loginReducer.user);

  const hideItem = useSelector((state) => state.loginReducer.hideItem);
  const [companyid, setCompanyid] = React.useState({
    value: user.agents[0].company_id,
    label: user.agents[0].gmcomname,
  });
  const [departmentData, setDepartmentData] = useState(null);
  const [departmentSelected, setDepartmentSelected] = useState("");
  const [extens, setExtens] = useState();
  const [sFrom, setSFrom] = useState(
    moment(moment().format(dateFormat), dateFormat1)
  );
  const [sTo, setSTo] = useState(
    moment(moment().add(1, "days").format(dateFormat), dateFormat1)
  );

  const [sFromTime, setSFromTime] = useState(null);
  const [sToTime, setSToTime] = useState(null);

  const [scalltype, setScalltype] = useState("");
  const [scallStatus, setScallStatus] = useState("");
  const [stel, setStel] = useState("");
  const [sexten, setSexten] = useState("");
  const [data, setData] = useState();
  const [sttBegin, setSttBegin] = useState(1);
  const [dataSum, setDataSum] = useState();
  const [refressSearch, setRefressSearch] = useState(false);

  const [itoSelectMode, setItoSelectMode] = useState("");
  const [exten, setExten] = useState(null);
  const [refreshOptionExten, setRefreshOptionExten] = useState(true);
  const [sapxep,setSapxep] = useState("DESC");
  const handleOnChangeIto = async (value) => {
    console.log("[handleOnChangeIto]:value=", value);
    setExten(value);
  };
  async function fetchCompanyList(company_name) {
    console.log("fetching user", company_name);
    const body = await axiosi.get("companies", {
      params: { company_name, pageSize: 100 },
    });
    if (body?.data?.rows) {
      return body?.data?.rows.map((user) => ({
        label: user.company_name,
        value: user.id,
      }));
    } else {
      const a = [{ label: "---no data---", value: "" }];
      return a.map((i) => i);
    }
    // return axiosi.get('companies',{params:{company_name,pageSize:100}} ) // fetch('https://randomuser.me/api/?results=5')
    //   .then((body) =>
    //     body?.data?.rows.map((user) => ({
    //      label: user.company_name,
    //       value: user.id,
    //     })),
    //   );
  }

  useEffect(() => {
    const getDepartmentData = async () => {
      try {
        const kq = await axiosi.get("department", {
          params: { company_id: companyid?.value, pageSize: 100 },
        });
        console.log("[getDepartmentData]:kq.data.rows=", kq.data.rows);
        setDepartmentData(kq.data.rows);
        setDepartmentSelected("");

        // console.log("[getDepartmentData]:kq.data.rows.length=",kq.data.rows.length);
        // console.log("[getDepartmentData]:user.agents[0].logdepartments.length=",user.agents[0].logdepartments.length);
      } catch (err) {
        setDepartmentData(null);
        console.log("[useEffect]:getDepartmentData has an Error !");
      }
    };
    getDepartmentData();
  }, [companyid?.value]);

  const handleOnChangeDepartment = (value) => {
    console.log("[handleOnChangeDepartment]:value=", value);
    setDepartmentSelected(value);
    setExten(null);
    setRefreshOptionExten((pre) => !pre);
  };

  async function fetchExtenList(exten) {
    console.log("[fetchExtenList]:exten=", exten);
    const body = await axiosi.get("agents/searchExten", {
      params: {
        company_id: companyid?.value,
        department_id: departmentSelected,
        exten,
        pageSize: 200,
      },
    });
    if (body?.data?.rows) {
      return body?.data?.rows.map((user) => ({
        label: user.name,
        value: user.endpoint_id,
      }));
    } else {
      const a = [{ label: "---no data---", value: "" }];
      return a.map((i) => i);
    }
  }

  useEffect(() => {
    const getDepartmentData = async () => {
      try {
        const kq = await axiosi.get("department", {
          params: { company_id: companyid?.value, pageSize: 100 },
        });
        console.log(kq.data.rows);
        setDepartmentData(kq.data.rows);
        // setDepartmentSelected("");
        setDepartmentSelected(user && user?.agents[0].logdepartments);
      } catch (err) {
        setDepartmentData(null);
        console.log("[useEffect]:getDepartmentData has an Error !");
      }
    };
    getDepartmentData();
  }, [companyid?.value]);

  useEffect(() => {
    // setExtens(null);
    setExten(null);

    setRefreshOptionExten((pre) => !pre);
  }, [companyid?.value]);

  useEffect(() => {
    const getExtens = async () => {
      const kq = await axiosi.get("users/getExtens");
      const kqdata = await kq.data;
      console.log("[CallLog-extens]:", kqdata);
      setExtens(kqdata);
    };
    getExtens();
  }, []);

  const style = {
    //  background: 'rgb(178, 227, 156) none repeat scroll 0% 0%',
    display: "flex",
    flexDirection: "column",
    padding: "8px 0",
  };
  // const style = { padding: '8px 0' };
  function onChangeSFrom(date, dateString) {
    // console.log("[onChangeSFrom]:",date, dateString);
    // if (!date) {
    //   console.log("[onChangeSFrom]:setSFrom('2022-05-01 00:00')");
    //   setSFrom('2022-01-01 00:00');
    // return;
    // };
    setSFrom(dateString);
  }
  // function onSelectSFrom(date, dateString) {

  //   console.log("[onSelectSFrom]2:",{date, dateString});
  //   if (!date) {
  //     console.log("[onSelectSFrom]:setSFrom('2022-05-01 00:00')");
  //     setSFrom('2022-05-01 00:00');
  //   return;
  //   };
  //   console.log("[onSelectSFrom]:sFrom=",sFrom);

  //   // console.log("[onSelectSFrom]:date._d0=",date._d);
  //   console.log("[onSelectSFrom]:date._d.format(dateFormat1)  =",moment(date._d).format(dateFormat1));
  //   // console.log("[onSelectSFrom]:date._d1=",date._d);
  //   // moment(moment().format(dateFormat), dateFormat1)
  //   setSFrom(moment(date._d).format(dateFormat1))
  // }

  // function onClearSFrom(){
  //   console.log("[onClearSFrom]");
  //   setSFrom('2022-05-01 00:00');
  // }
  function onChangeSTo(date, dateString) {
    // console.log(date, dateString);
    setSTo(dateString);
  }
  function handleChange(value) {
    console.log(`selected ${value}`);
  }
  const handleChangesTel = (e) => {
    // console.log(e.target.value)
    setStel(e.target.value);
  };
  const handleExportCallLog0 = async () => {
    try {
      let timeFrom = "";
      if (typeof sFrom === "object") {
        timeFrom = sFrom.format(dateFormat);
        console.log("sFrom=", sFrom.format(dateFormat));
      } else {
        console.log("sFrom STRING=", sFrom);
        if (sFrom) {
          timeFrom = sFrom;
          console.log("CÓ sFROM");
        } else {
          console.log("KG CÓ SFORM");
        }
      }

      let timeTo = "";
      if (typeof sTo === "object") {
        timeTo = sTo.format(dateFormat);
        console.log("sTo:=", sTo.format(dateFormat));
      } else {
        timeTo = sTo;
        console.log("sTo STRING:=", sTo);
      }

      const sdata = {
        page,
        timeFrom,
        timeTo,
        scallStatus,
        scalltype,
        sexten,
        stel,
        timeFromTime: sFromTime === null ? "" : sFromTime.format(timeFormat),
        timeToTime: sToTime === null ? "" : sToTime.format(timeFormat),
      };
      console.log(sdata);
      const kq = await axiosi.get("calllogs/getExportCallLogs", {
        params: sdata,
      });
      const kqdata = await kq.data;
      if (kq.status !== 204) {
        setData(kq.data);
        console.log("[getExportCallLogs]:", kqdata);
      } else {
        console.log("[No Content] kq.data=", kq.data);
      }
    } catch (error) {
      console.log("[handleExportCallLog]:", error);
    }
    // console.log(sFrom,sTo);
    // console.log("::::::::::::",typeof(sFrom),typeof(sTo));
  };
  const btnExportSummaryOnClick = async () => {
    try {
      let timeFrom = "";
      if (typeof sFrom === "object") {
        timeFrom = sFrom.format(dateFormat);
        console.log("sFrom=", sFrom.format(dateFormat));
      } else {
        console.log("sFrom STRING=", sFrom);
        if (sFrom) {
          timeFrom = sFrom;
          console.log("CÓ sFROM");
        } else {
          console.log("KG CÓ SFORM");
        }
      }

      let timeTo = "";
      if (typeof sTo === "object") {
        timeTo = sTo.format(dateFormat);
        console.log("sTo:=", sTo.format(dateFormat));
      } else {
        timeTo = sTo;
        console.log("sTo STRING:=", sTo);
      }

      console.log("UUUUUUUUUUUUUUUUUUUUUUUUUUUERRRRRRRRRRRRRRRRRRRRR");
      console.log({ user });
      const sdata = {
        page,
        timeFrom,
        timeTo,
        pickupSecond,
        scallStatus,
        scalltype,
        sexten: exten?.value,
        company_id: companyid?.value,
        department_id: departmentSelected,
        stel,
        timeFromTime: sFromTime === null ? "" : sFromTime.format(timeFormat),
        timeToTime: sToTime === null ? "" : sToTime.format(timeFormat),
        deplength: Object.keys(user?.deps).length,
      };
      console.log("[btnExportSummaryOnClick]:sdata=", { sdata });
      // return;
      const kq = await axiosi.get("calllogs/getExportCallLogsSumfile", {
        params: sdata,
        responseType: "blob",
      });
      console.log("[btnExportSummaryOnClick]:kq=", kq);
      console.log("[btnExportSummaryOnClick]:kq.data=", kq.data);
      // const kqtext=await kq.data.text();
      // console.log("[handleExportCallLog]:kq.data.text()=",kqtext)
      // console.log("[handleExportCallLog]:JSON.parse(kq.data.text())=",JSON.parse(kqtext))
      console.log("[btnExportSummaryOnClick]:typeof kq.data=", typeof kq.data);
      console.log("[btnExportSummaryOnClick]:kq.data.type=", kq.data.type);

      // var enc = new TextDecoder('utf-8')
      // var res =  JSON.parse(enc.decode (new Uint8Array( kq.data )));// convert to JSON object
      // console.log(res)

      // var b = new Blob([JSON.stringify({"test": "toast"})], {type : "application/json"})

      // console.log("[handleExportCallLog]:JSON.parse(kq.data)=",JSON.parse(kq.data))
      // console.log("[handleExportCallLog]:JSON.stringify(kq.data)=", JSON.stringify(kq.data))
      // const url = window.URL.createObjectURL(new Blob([kq.data],{type : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}));
      const url = window.URL.createObjectURL(new Blob([kq.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `exportCallLogSum.xlsx`); //or any other extension
      document.body.appendChild(link);
      link.click();
      link.remove();

      // const kqdata= await kq.data;
      // if (kq.status!==204) {
      // // setData(kq.data)
      // console.log("[getExportCallLogs]:",kqdata);
      // } else {
      //   console.log("[No Content] kq.data=",kq.data)
      // }
    } catch (error) {
      console.log("[btnExportSummaryOnClick]:", error);
    }
  };
  const handleExportCallLog = async () => {
    try {
      let timeFrom = "";
      if (typeof sFrom === "object") {
        timeFrom = sFrom.format(dateFormat);
        console.log("sFrom=", sFrom.format(dateFormat));
      } else {
        console.log("sFrom STRING=", sFrom);
        if (sFrom) {
          timeFrom = sFrom;
          console.log("CÓ sFROM");
        } else {
          console.log("KG CÓ SFORM");
        }
      }

      let timeTo = "";
      if (typeof sTo === "object") {
        timeTo = sTo.format(dateFormat);
        console.log("sTo:=", sTo.format(dateFormat));
      } else {
        timeTo = sTo;
        console.log("sTo STRING:=", sTo);
      }

      const sdata = {
        pageSize: 2000,
        timeFrom,
        timeTo,
        scallStatus,
        scalltype,
        sexten: exten?.value,
        company_id: companyid?.value,
        department_id: departmentSelected,
        stel,
        timeFromTime: sFromTime === null ? "" : sFromTime.format(timeFormat),
        timeToTime: sToTime === null ? "" : sToTime.format(timeFormat),
        deplength: Object.keys(user?.deps).length,
      };
      console.log(sdata);
      const kq = await axiosi.get("calllogs/getExportCallLogsfile", {
        params: sdata,
        responseType: "blob",
      });
      console.log("[handleExportCallLog]:kq=", kq);
      console.log("[handleExportCallLog]:kq.data=", kq.data);
      // const kqtext=await kq.data.text();
      // console.log("[handleExportCallLog]:kq.data.text()=",kqtext)
      // console.log("[handleExportCallLog]:JSON.parse(kq.data.text())=",JSON.parse(kqtext))
      console.log("[handleExportCallLog]:typeof kq.data=", typeof kq.data);
      console.log("[handleExportCallLog]:kq.data.type=", kq.data.type);

      // var enc = new TextDecoder('utf-8')
      // var res =  JSON.parse(enc.decode (new Uint8Array( kq.data )));// convert to JSON object
      // console.log(res)

      // var b = new Blob([JSON.stringify({"test": "toast"})], {type : "application/json"})

      // console.log("[handleExportCallLog]:JSON.parse(kq.data)=",JSON.parse(kq.data))
      // console.log("[handleExportCallLog]:JSON.stringify(kq.data)=", JSON.stringify(kq.data))
      // const url = window.URL.createObjectURL(new Blob([kq.data],{type : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}));
      const url = window.URL.createObjectURL(new Blob([kq.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `exportCallLogs.xlsx`); //or any other extension
      document.body.appendChild(link);
      link.click();
      link.remove();

      // const kqdata= await kq.data;
      // if (kq.status!==204) {
      // // setData(kq.data)
      // console.log("[getExportCallLogs]:",kqdata);
      // } else {
      //   console.log("[No Content] kq.data=",kq.data)
      // }
    } catch (error) {
      console.log("[handleExportCallLog]:", error);
    }
    // console.log(sFrom,sTo);
    // console.log("::::::::::::",typeof(sFrom),typeof(sTo));
  };
  const handleSearchCallLog = async () => {
    try {
      let timeFrom = "";
      if (typeof sFrom === "object") {
        timeFrom = sFrom.format(dateFormat1);
        console.log("sFrom=", sFrom.format(dateFormat1));
      } else {
        console.log("sFrom STRING=", sFrom);
        if (sFrom) {
          timeFrom = sFrom;
          console.log("CÓ sFROM");
        } else {
          console.log("KG CÓ SFORM");
        }
      }

      let timeTo = "";
      if (typeof sTo === "object") {
        timeTo = sTo.format(dateFormat1);
        console.log("sTo:=", sTo.format(dateFormat1));
      } else {
        timeTo = sTo;
        console.log("sTo STRING:=", sTo);
      }

      console.log("[Calllog Search]: exten=", exten);
      // let searchall='[]';

      // if ((user.agents[0].company_id!==1)&&(departmentData.length!==user.agents[0].logdepartments.length)){
      //   searchall=JSON.stringify(user.agents[0].logdepartments)
      // }

      // const sdata={page,timeFrom,timeTo,scallStatus,scalltype,
      //   sexten:exten?.value,company_id:companyid?.value,
      //   department_id:(departmentSelected==='')?searchall:`[${departmentSelected}]`,
      //   stel,
      //   timeFromTime:sFromTime===null?'':sFromTime.format(timeFormat),
      //   timeToTime:sToTime===null?'':sToTime.format(timeFormat),
      //   logdepartments:JSON.stringify(user.agents[0].logdepartments)
      // }

      console.log("before sdata");
      const sdata = {
        page,
        timeFrom,
        timeTo,
        scallStatus,
        scalltype,
        sexten: exten?.value,
        company_id: companyid?.value,
        department_id: departmentSelected,
        stel,
        timeFromTime: sFromTime === null ? "" : sFromTime.format(timeFormat),
        timeToTime: sToTime === null ? "" : sToTime.format(timeFormat),
        deplength: Object.keys(user?.deps).length,
        sapxep: sapxep,
      };
      console.log(sdata);
      const kq = await axiosi.get("calllogs/getCallLogs", { params: sdata });
      const kqdata = await kq.data;
      if (kq.status !== 204) {
        setData(kq.data);
        setDataSum(null);
        
        setSttBegin((kq.data.page-1)*kq.data.pageSize +1)
        
        console.log("[CallLog-extens]:", kqdata);
      } else {
        console.log("[No Content] kq.data=", kq.data);
        setData(null);
        setDataSum(null);
      }
    } catch (error) {
      console.log("[handleSearchCallLog]:", error);
      setData(null);
      setDataSum(null);
    }
    // console.log(sFrom,sTo);
    // console.log("::::::::::::",typeof(sFrom),typeof(sTo));
  };
  const handleSearchCallLogSum = async () => {
    try {
      // setPage(1);
      let timeFrom = "";
      if (typeof sFrom === "object") {
        timeFrom = sFrom.format(dateFormat);
        console.log("sFrom=", sFrom.format(dateFormat));
      } else {
        console.log("sFrom STRING=", sFrom);
        if (sFrom) {
          timeFrom = sFrom;
          console.log("CÓ sFROM");
        } else {
          console.log("KG CÓ SFORM");
        }
      }

      let timeTo = "";
      if (typeof sTo === "object") {
        timeTo = sTo.format(dateFormat);
        console.log("sTo:=", sTo.format(dateFormat));
      } else {
        timeTo = sTo;
        console.log("sTo STRING:=", sTo);
      }

      const sdata = {
        page: 1,
        timeFrom,
        timeTo,
        pickupSecond,
        scallStatus,
        scalltype,
        sexten: exten?.value,
        company_id: companyid?.value,
        department_id: departmentSelected,
        stel,
        timeFromTime: sFromTime === null ? "" : sFromTime.format(timeFormat),
        timeToTime: sToTime === null ? "" : sToTime.format(timeFormat),
        deplength: Object.keys(user?.deps).length,
      };
      console.log(sdata);
      const kq = await axiosi.get("calllogs/getCallLogsSum", { params: sdata });
      console.log(kq);

      const kqdata = await kq.data; //agentCallLogsSum

      console.log({ kqdata });
      // return;
      if (kq.status !== 204) {
        setDataSum(kq.data);
        setData(null);
        console.log("[handleSearchCallLogSum]:", kqdata);
      } else {
        console.log("[handleSearchCallLogSum-No Content] kq.data=", kq.data);
        setDataSum(null);
        setData(null);
      }
    } catch (error) {
      console.log("[handleSearchCallLogSum]:", error);
      setDataSum(null);
      setData(null);
    }
    // console.log(sFrom,sTo);
    // console.log("::::::::::::",typeof(sFrom),typeof(sTo));
  };
  const btnSummaryOnClick = () => {
    console.log("[btnSummaryOnClick]");
    handleSearchCallLogSum();
  };
  const btnSearchOnClick = () => {
    console.log("[btnSearchOnClick]:page=", page);
    if (page === 1) {
      console.log("[btnSearchOnClick page=1] : set setRefressSearch:");
      setRefressSearch((pre) => !pre);
    } else {
      console.log("[btnSearchOnClick page!=1] : set setpage=1:");
      setPage(1);
    }
  };
  useEffect(() => {
    console.log("doing handleSearchCallLog");
    handleSearchCallLog();
  }, [page, refressSearch,sapxep]);
  useEffect(() => {
    console.log("doing handleSearchCallLog");

    setTimeout(handleSearchCallLog, 3000);
  }, [refreshCallLog]);

  //Time From Time
  function onChangeSFromTime(time, timeString) {
    console.log("[onChangeSFromTime]:", { time, timeString });
    setSFromTime(time);
  }
  function onSelectSFromTime(time) {
    console.log("onSelectSFromTime]:=", time.format(timeFormat));
    console.log("[onSelectSFromTime]:", { time, timeString: time?._i });
    setSFromTime(time);
  }

  //Time To Time
  function onChangeSToTime(time, timeString) {
    console.log("[onChangeSToTime]:", { time, timeString });
    setSToTime(time);
  }
  function onSelectSToTime(time) {
    console.log("[onSelectSToTime]:", time?._d);
    console.log("[onSelectSToTime]:", { time, timeString: time?._i });
    setSToTime(time);
  }

  function checkLogDep(item) {
    if (user.agents[0].company_id === 1) return true;
    const logdeps = user.agents[0].logdepartments;
    // console.log("[checkLogDep]:logdeps=",logdeps);
    // console.log("[checkLogDep]:item.id=",item.id);
    // console.log("[checkLogDep]:logdeps.includes(item.id)=",logdeps.includes(item.id));
    return logdeps.includes(item.id);
  }
  return (
    <div>
      <Typography.Title level={3}>Search :</Typography.Title>

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" span={4}>
          <div style={style}>
            <Text>From :</Text>
            <DatePicker
              // showTime
              onChange={onChangeSFrom}
              // onSelect={onSelectSFrom}
              // onClear={onClearSFrom}
              // value={moment(sFrom,dateFormat1)}
              defaultValue={sFrom}
              format={dateFormat1}
            />
          </div>
        </Col>

        <Col className="gutter-row" span={4}>
          <div style={style}>
            <Text>To :</Text>
            <DatePicker
              showTime
              onChange={onChangeSTo}
              defaultValue={sTo}
              format={dateFormat1}
            />
          </div>
        </Col>

        <Col className="gutter-row" span={4}>
          <div style={style}>
            <Text>Call Direction :</Text>
            <Select
              defaultValue=""
              style={{ width: "100%" }}
              onChange={(value) => setScalltype(value)}
            >
              <Option value="">All</Option>
              <Option value="in">in</Option>
              <Option value="out">out</Option>
              <Option value="pbx">pbx</Option>
            </Select>
          </div>
        </Col>

        <Col className="gutter-row" span={4}>
          <div style={style}>
            <Text>Connected :</Text>
            <Select
              defaultValue=""
              style={{ width: "100%" }}
              onChange={(value) => setScallStatus(value)}
            >
              <Option value="">All</Option>
              <Option value="1">Connected</Option>
              <Option value="0">Not Connected</Option>
              <Option value="vm">Voice Mail</Option>
            </Select>
          </div>
        </Col>
        <Col className="gutter-row" span={4}>
          <div style={style}>
            <Text>Tel :</Text>
            <Input placeholder="tel" onChange={handleChangesTel} />
          </div>
        </Col>
        <Col className={`gutter-row ${hideItem} `} span={4}>
          <div style={style}>
            <Text>Company :</Text>
            <DebounceSelect
              showSearch={true}
              value={companyid}
              placeholder=""
              fetchOptions={fetchCompanyList}
              onChange={(newValue) => {
                setCompanyid(newValue);
              }}
              style={{ width: "100%" }}
            />
          </div>
        </Col>

        {/* <Col className="gutter-row" span={4}>
              <div style={style}>
                <Text>Exten :</Text>
                <Select defaultValue=""  onChange={(value)=>setSexten(value)}>
                  <Option value="">All</Option>        
                  {extens?extens.map((exten)=>(
                  <Option key={exten.endpoint_id} value={exten.endpoint_id}>{exten.endpoint_id} - {exten.name}</Option>)
                  ):<span></span>}        
               </Select>        
              </div>
          </Col> */}
      </Row>

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" span={4}>
          <div style={style}>
            <Text>From :</Text>
            <TimePicker
              value={sFromTime}
              onChange={onChangeSFromTime}
              onSelect={onSelectSFromTime}
              minuteStep={15}
              //  defaultOpenValue={moment('08:00', timeFormat)} format={timeFormat}
              defaultValue={moment("08:00", timeFormat)}
              format={timeFormat}
            />
          </div>
        </Col>
        <Col className="gutter-row" span={4}>
          <div style={style}>
            <Text>To :</Text>
            <TimePicker
              value={sToTime}
              onChange={onChangeSToTime}
              onSelect={onSelectSToTime}
              minuteStep={15}
              // defaultOpenValue={moment('17:00', timeFormat)}
              defaultValue={moment("17:00", timeFormat)}
              format={timeFormat}
            />
          </div>
        </Col>
        
        <Col className="gutter-row" span={12}>
          <div style={style}>
            <Text>Agent :</Text>
            <DebounceSelect
              selectMode={itoSelectMode}
              refreshOption={refreshOptionExten}
              showSearch={true}
              value={exten}
              placeholder=""
              fetchOptions={fetchExtenList}
              onChange={handleOnChangeIto}
              style={{ width: "100%" }}
            />
          </div>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" span={20}>
          <div>
            <Text>Department :</Text>
            <Select
              mode="multiple"
              
              style={{ width: "100%" }}
              onChange={handleOnChangeDepartment}
              value={departmentSelected}
            >
              <Option value=""></Option>
              {departmentData &&
                departmentData.map((item) => {
                  if (checkLogDep(item)) {
                    return (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    );
                  }
                })}
            </Select>
          </div>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" span={4}>
          <div style={style}>
            <Text> &nbsp;</Text>
            <Button onClick={btnSearchOnClick}>Search</Button>
          </div>
        </Col>
        <Col className="gutter-row" span={4}>
          <div style={style}>
            <Text>&nbsp;</Text>
            <Button onClick={handleExportCallLog}>Export</Button>
          </div>
        </Col>
        <Col className="gutter-row" span={4}>
          <div style={style}>
            <Text>&nbsp;</Text>
            <Button onClick={btnSummaryOnClick}>Summary</Button>
          </div>
        </Col>
        <Col className="gutter-row" span={8}>
          <div style={style}>
            <Text>&nbsp;</Text>
            <Button onClick={btnExportSummaryOnClick}>Export Summary</Button>
          </div>
        </Col>
        {/* <Col  span={12}>
        <div style={style} className='divBtnSearch'>
          <Button onClick={btnSearchOnClick}>Search</Button>
          <Button onClick={handleExportCallLog}>Export</Button>
          <Button onClick={btnSummaryOnClick}>Summary</Button>
          <Button onClick={btnExportSummaryOnClick}>Export Summary</Button>
        </div>
    </Col> */}
      </Row>
      {/* <Button onClick={handleGetEndpoint}>getEndpoint</Button>
            <Button onClick={()=>console.log("user=",user)}>getUser</Button> */}
      <Row>
        <Col span={24}>
          {dataSum ? (
            <ShowSummary data={dataSum} setRefressSearch={setRefressSearch} />
          ) : (
            <span></span>
          )}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {data ? (
            <ShowCallLogTable
              data={data.rows}
              setRefressSearch={setRefressSearch}
              sttBegin={sttBegin}
              sapxep={sapxep}
              setSapxep={setSapxep}
            />
          ) : (
            <span></span>
          )}
        </Col>
      </Row>
      {/* <Row>
              <Col span={24}>
                {data?.pages>1?<PageLink pageSize={data.pageSize} searchpage={"calllog"} pages={data.pages} numrows={data.numrows} page={data.page} />:<h1></h1>}
              </Col>
            </Row> */}
      <Row>
        <Col span={24}>
          {data?.pages > 1 ? (
            <PageLink1
              pageSize={data.pageSize}
              searchpage={"calllog"}
              pages={data.pages}
              numrows={data.numrows}
              page={page}
              setPage={setPage}
            />
          ) : (
            <h1></h1>
          )}
        </Col>
      </Row>
    </div>
  );
}
