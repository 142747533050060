import React, { useState, useEffect } from "react";
import { Column } from "@ant-design/plots";
export default function ShowChart({ data }) {
  
  const [config, setConfig] = useState(null);

  useEffect(() => {
    
    if (data.length === 0) return;

     // 1. Tự động trích xuất xOrder và departmentOrder
     const xOrder = Array.from(
      new Set(data.map((item) => item.ivrkey))
    ).sort(); // Loại bỏ trùng lặp và sắp xếp
    const departmentOrder = Array.from(
      new Set(data.map((item) => item.department_name))
    ).filter(Boolean); // Loại bỏ giá trị rỗng

    // Đảm bảo "Total" luôn ở cuối
    if (departmentOrder.includes("Total")) {
      departmentOrder.splice(departmentOrder.indexOf("Total"), 1);
      departmentOrder.push("Total");
    }

    // 1. Thêm cột "Total" với giá trị tổng quantity cho từng `ivrkey`
    const departmentTotals = data.reduce((acc, item) => {
      const { ivrkey, quantity } = item;
      acc[ivrkey] = (acc[ivrkey] || 0) + quantity;
      return acc;
    }, {});

    const updatedData = [
      ...data,
      ...Object.keys(departmentTotals).map((ivrkey) => ({
        department_name: "Total",
        ivrkey,
        quantity: departmentTotals[ivrkey],
      })),
    ];

    // 2. Định nghĩa thứ tự trục X và department_name
    // const xOrder = ["", "1", "2", "3", "5", "6", "7", "8", "9"];
    // const departmentOrder = ["", "dep1", "dep2", "dep3", "Total"];

    // 3. Sắp xếp dữ liệu theo `xOrder` và `departmentOrder`
    const sortedData = updatedData.sort((a, b) => {
      const xComparison = xOrder.indexOf(a.ivrkey) - xOrder.indexOf(b.ivrkey);
      if (xComparison !== 0) return xComparison;
      return (
        departmentOrder.indexOf(a.department_name) -
        departmentOrder.indexOf(b.department_name)
      );
    });

    // 4. Cấu hình đồ thị
    const config = {
      data: sortedData,
      xField: "ivrkey",
      yField: "quantity",
      seriesField: "department_name",
      isGroup: true,
      legend: {
        position: "top-left",
        itemName: {
          formatter: (name) => {
            const index = departmentOrder.indexOf(name);
            return index !== -1 ? departmentOrder[index] : name;
          },
        },
      },
      xAxis: {
        title: {
          text: "IVR Key",
          style: { fontSize: 14, fontWeight: "bold" },
        },
      },
      meta: {
        ivrkey: {
          alias: "IVR Key",
          values: xOrder, // Định nghĩa thứ tự trên trục X
        },
      },
      yAxis: {
        title: {
          text: "Quantity",
          style: { fontSize: 14, fontWeight: "bold" },
        },
      },
      label: {
        position: "middle",
        style: {
          fill: "#FFFFFF",
          fontSize: 12,
        },
      },
    };

    setConfig(config);
  }, [data]);

  return (
    <>
      

      <div className="DashboardDisplay">
        <h2 className="gachduoi">Summary</h2>
        
        {
        data.length > 0 &&   config &&  config.data.length>0 && <Column {...config} />
        }
      </div>
    </>
  );
}
