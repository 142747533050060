import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import axios from "axios";
import { Table, Tag, Space, Button, Typography, Drawer } from "antd";
import {
  StepForwardOutlined,
  DownloadOutlined,
  PhoneOutlined,
  UserOutlined,
  HomeOutlined,
  FlagOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  MailOutlined,
  InfoCircleOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from "@ant-design/icons";
import useAxios from "./../../../CustomHooks/useAxios";
const dateFormat = "YYYY-MM-DD HH:mm:ss";
const ShowTable = ({ data, setRefressSearch, sttBegin,sapxep,setSapxep }) => {
  let stt=(sttBegin)?sttBegin:1;
  const { axiosi } = useAxios();
  const user = useSelector((state) => state.loginReducer.user);
  // const [sapxep, setSapxep] = useState("DESC");
  return (
    <>
      <table className="table1">
          <thead>
            <tr>
              
              <th>No.</th>
              
              <th width="250px"><div style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>Time
                <div> <ArrowUpOutlined style={{cursor:"pointer","color":(sapxep==="ASC")&&"red"}} title="Tăng dần" onClick={()=>setSapxep("ASC")} />
                      <ArrowDownOutlined style={{cursor:"pointer","color":(sapxep==="DESC")&&"red"}} title="Giảm dần" onClick={()=>setSapxep("DESC")} /></div>
                </div>
              </th>
              <th>Department</th>
              <th width="220px">Tel</th>
              <th width="220px">Agent</th>

              <th>IVR Key</th>
              
              
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr key={item.id}>
                
                <td>{stt++}</td>
                
                <td>
                  <div>
                    
                    {moment(item.attime).format(dateFormat)}
                  </div>
                  

                </td>
                <td>
                  {item.department_name}
                  
                </td>
                <td>
                  {item.tel}
                  
                </td>
                <td>
                  {item.agent_name}
                </td>
                <td>
                  {item.ivrkey}
                </td>
                
              </tr>
            ))}
          </tbody>
        </table>
    </>
  )
}

export default ShowTable