import { useState, useRef } from "react";

import { Button, Card, message, Row, Col, Input } from "antd";
import {
  MenuOutlined,
  PhoneOutlined,
  PauseOutlined,
  SwapOutlined,
  CaretRightOutlined,
  AudioMutedOutlined,
  AudioOutlined,
  PlayCircleOutlined,
  UserSwitchOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import TransferTo from "./TransferTo";
export default function Numpad({
  mysession,
  mysessionInfo,
  handleHangup,
  callStatus,
  callTime,
  addDebugLog,
  addDebugLogMessage,
  resetDebugLog,
}) {
  const [transferTo, setTransferTo] = useState("");
  const [mutedClass, setMutedClass] = useState("");
  const [onHoldClass, setOnHoldClass] = useState("");
  const [transferStatus, setTransferStatus] = useState("");
  const [myTransferCallStatus, setMyTransferCallStatus] = useState("");
  const [disableClass, setDisableClass] = useState("");
  const makeCall = useSelector((state) => state.jsSIPReducer.makeCall);
  const user = useSelector((state) => state.loginReducer.user);
  const sessionTransferTo = useRef(null);
  const testRef = useRef(1);

  const [mute, setmute] = useState(false);
  const [hold, sethold] = useState(false);
  const [useUpdate, setUseUpdate] = useState(true);
  const [hideItem, setHideItem] = useState("hideItem");
  const [reRender, setReRender] = useState(false);

  const changeTestRef = () => {
    testRef.current = testRef.current + 1;
  };
  const showTestRef = () => {
    console.log("testRef.current=", testRef.current);
  };
  ////////////////////////
  const handleHangup_numpad = () => {
    if (disableClass != "") {
      return;
    }
    addDebugLog({ tel: mysessionInfo?.tel, message: "handleHangup_numpad" });
    handleHangup();
  };
  const toggleMute = () => {
    if (disableClass != "") {
      return;
    }
    const { audio } = mysession.isMuted();
    if (audio) {
      mysession.unmute();
      setMutedClass("");
    } else {
      mysession.mute();
      setMutedClass("color-red");
    }
    addDebugLog({ tel: mysessionInfo?.tel, message: "toggleMute" });
  };
  const toggleHold = () => {
    if (disableClass != "") {
      return;
    }
    const { local } = mysession.isOnHold();
    console.log("local Hold =", local);
    if (local) {
      mysession.unhold(
        {
          extraHeaders: ["X-UnHold-Reason: User requested"],
        },
        () => setOnHoldClass("")
      );
    } else {
      mysession.hold(
        {
          extraHeaders: ["X-Hold-Reason: User requested"],
        },
        () => setOnHoldClass("color-red")
      );
    }
    addDebugLog({ tel: mysessionInfo?.tel, message: "toggleHold" });
  };
  const handleTransfer = () => {
    if (disableClass != "") {
      return;
    }
    switch (transferStatus) {
      case "":
        setTransferStatus("waitForTransfer");
        mysession.hold(
          {
            extraHeaders: ["X-Hold-Reason: hold for transfer requested"],
          },
          () => setOnHoldClass("color-red")
        );
        break;
      case "waitForTransfer":
        setTransferStatus("");
        mysession.unhold(
          {
            extraHeaders: ["X-unHold-Reason: turn off transfer requested"],
          },
          () => setOnHoldClass("")
        );
        break;
    }
    if (transferStatus === "") {
    } else {
    }
    addDebugLog({ tel: mysessionInfo?.tel, message: "handleTransfer" });
  };
  function callForTransfer() {
    let callto = transferTo.trim();
    if (callto.length < 4) {
      return;
    }
    setTransferStatus("waitForTransfer_Calling");
    testRef.current = 2;
    console.log("[callForTransfer]:testRef.current=", testRef.current);
    // return;
    sessionTransferTo.current = makeCall(transferTo, true);
    setDisableClass("disable");
    setMyTransferCallStatus("Calling...");
    setReRender(!reRender);
    console.log("sessionTransferTo.current", sessionTransferTo.current);
    setMyTransferCallStatus("Ended");
    sessionTransferTo.current.on("ended", () => {
      setDisableClass("");
      setTransferStatus("waitForTransfer");
      sessionTransferTo.current = null;
    });
    sessionTransferTo.current.on("failed", () => {
      setDisableClass("");
      setMyTransferCallStatus("Failed");
      console.log("transfer failed,setTransferStatus to waitForTransfer");
      sessionTransferTo.current = null;
      setTransferStatus("waitForTransfer");
    });
    sessionTransferTo.current.on("progress", () => {
      setMyTransferCallStatus("progress...");
      setReRender((pre) => !pre);
    });
    sessionTransferTo.current.on("confirmed", () => {
      setMyTransferCallStatus("Answered");
      setTransferStatus("waitForTransfer_Answered");
    });
    console.log("[callForTransfer]:2testRef.current=", testRef.current);
    addDebugLog({ tel: mysessionInfo?.tel, message: "callForTransfer" });
  }
  function handleCancelCallTransfer() {
    sessionTransferTo.current.terminate();
    setTransferStatus("waitForTransfer");
    setMyTransferCallStatus("");
    addDebugLog({
      tel: mysessionInfo?.tel,
      message: "handleCancelCallTransfer",
    });
  }
  function handleCancelTransfer() {
    sessionTransferTo.current.terminate();
    setTransferStatus("waitForTransfer");
    addDebugLog({ tel: mysessionInfo?.tel, message: "handleCancelTransfer" });
  }
  function transferCallsWithReplaces(session1) {
    console.log("transferCallsWithReplaces,testRef.current=", testRef.current);
    console.log("transferCallsWithReplaces,session1=", session1);
    console.log(
      "transferCallsWithReplaces,sessionTransferTo.current=",
      sessionTransferTo.current
    );
    // return;
    let session2 = sessionTransferTo.current;
    try {
      if (session1.isEstablished() && session2.isEstablished()) {
        let SIP_USER = user?.agents[0]?.endpoint_id;
        let server = process.env.SIP_DOMAIN;
        const session2CallId = session2._request.call_id;
        const session2FromTag = session2._from_tag;
        const session2ToTag = session2._to_tag;

        const replacesHeader = `${session2CallId};to-tag=${session2ToTag};from-tag=${session2FromTag}`;

        const targetUri = session2.remote_identity.uri.clone();
        targetUri.setHeader("Replaces", encodeURIComponent(replacesHeader));

        const referEventHanlers = {
          requestSucceeded: (referEvent_data) => {
            console.log("referEvent_data,requestSucceeded:", referEvent_data);
          },
          requestFailed: (referEvent_data) => {
            console.log("referEvent_data,requestFailed:", referEvent_data);
          },
          trying: (referEvent_data) => {
            console.log("referEvent_data,trying:", referEvent_data);
          },
          progress: (referEvent_data) => {
            console.log("referEvent_data,progress:", referEvent_data);
          },
          accepted: (referEvent_data) => {
            console.log("referEvent_data,accepted:", referEvent_data);
            session1.terminate();
          },
          failed: (referEvent_data) => {
            console.log("referEvent_data, failed:", referEvent_data);
          },
        };
        // Thực hiện REFER request với URI object
        session1.refer(targetUri, {
          extraHeaders: [`Referred-By: <sip:${SIP_USER}@${server}>`],
          eventHandlers: referEventHanlers,
        });
      } else {
        throw new Error("One or both sessions are not established");
      }
    } catch (error) {
      console.error("Error during transfer:", error);
      addDebugLog({
        tel: mysessionInfo?.tel,
        message: "transferCallsWithReplaces error",
      });
      throw error;
    }
    addDebugLog({
      tel: mysessionInfo?.tel,
      message: "transferCallsWithReplaces",
    });
  }
  function handleTransferCall() {
    let SIP_USER = user?.agents[0]?.endpoint_id;
    let company_id = user?.agents[0]?.company_id;
    let department_id = user?.agents[0]?.department_id;
    let tel=mysessionInfo?.tel;
    let server ="gcall.com.vn";// process.env.SIP_DOMAIN;
    let transferTo=`svc$${company_id}$${department_id}$${SIP_USER}$${tel}`;
    console.log("handleTransferCall");
    addDebugLog({ tel: mysessionInfo?.tel, message: "handleTransferCall" });

    mysession.refer(`sip:${transferTo}@${server}`, {
      extraHeaders: [`Referred-By: <sip:${SIP_USER}@${server}>`,`CompanyId: <123>` ],
      eventHandlers: {    
        requestSucceeded: function (event) {
          console.log("REFER requestSucceeded");
          addDebugLog({
            tel: mysessionInfo?.tel,
            message: "REFER requestSucceeded",
          });
          mysession.terminate();
        },
        requestFailed: function (event) {
          console.log("REFER requestFailed");
          addDebugLog({
            tel: mysessionInfo?.tel,
            message: "REFER requestFailed",
          });
        },
        trying: function (event) {
          console.log("REFER trying");
          addDebugLog({ tel: mysessionInfo?.tel, message: "REFER trying" });
        },
        progress: function (event) {
          console.log("REFER progress");
          addDebugLog({ tel: mysessionInfo?.tel, message: "REFER progress" });
        },
        accepted: function (event) {
          console.log("REFER accepted");
          addDebugLog({ tel: mysessionInfo?.tel, message: "REFER accepted" });
        },
        failed: function (event) {
          console.log("REFER failed");
          addDebugLog({ tel: mysessionInfo?.tel, message: "REFER failed" });
        },
      },
    });
  }
  //////////////////////
  // const handleMute = () => {
  //   if (!mute) {
  //     mysession.mute();
  //     setmute((pre) => !pre);
  //   } else {
  //     mysession.unmute();
  //     setmute((pre) => !pre);
  //   }
  // };
  // const handleHold = () => {
  //   if (!hold) {
  //     mysession.hold({ useUpdate });
  //     console.log("sethold");
  //     sethold((pre) => !pre);
  //   } else {
  //     mysession.unhold({ useUpdate });
  //     mysession.renegotiate({ useUpdate });
  //     console.log("setunhold");
  //     sethold((pre) => !pre);
  //   }
  // };
  function doSendDTMF(digit) {
    console.log("[doSendDTMF]:digit=", digit);
    var options = {
      transportType: "RFC2833",
    };
    mysession.sendDTMF(digit, options);
    addDebugLog({ tel: mysessionInfo?.tel, message: "doSendDTMF" });
  }
  const handleShowHide = () => {
    setHideItem((pre) => (pre === "" ? "hideItem" : ""));
  };
  if (mysession?.isCallTransfer == true) {
    return <></>;
  }
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };
  return (
    <>
      <div className="numpad">
        <MenuOutlined
          className="showHideKeypad"
          onClick={handleShowHide}
          title="Show/hide Keypad"
        />

        {/* <TransferTo  />  */}
        <div className={`numpad_header ${hideItem}`}>Keypad </div>
        <div
          className={`numpad_header ${hideItem}`}
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Row className={hideItem}>
            <Col className="numpad_col_digit">
              <Button
                className="numpad_digit"
                onClick={() => {
                  doSendDTMF(1);
                }}
                shape="circle"
                type="primary"
              >
                1
              </Button>
            </Col>
            <Col className="numpad_col_digit">
              <Button
                className="numpad_digit"
                onClick={() => {
                  doSendDTMF(2);
                }}
                shape="circle"
                type="primary"
              >
                2
              </Button>
            </Col>
            <Col className="numpad_col_digit">
              <Button
                className="numpad_digit"
                onClick={() => {
                  doSendDTMF(3);
                }}
                shape="circle"
                type="primary"
              >
                3
              </Button>
            </Col>
          </Row>
          <Row className={hideItem}>
            <Col className="numpad_col_digit">
              <Button
                className="numpad_digit"
                onClick={() => {
                  doSendDTMF(4);
                }}
                shape="circle"
                type="primary"
              >
                4
              </Button>
            </Col>
            <Col className="numpad_col_digit">
              <Button
                className="numpad_digit"
                onClick={() => {
                  doSendDTMF(5);
                }}
                shape="circle"
                type="primary"
              >
                5
              </Button>
            </Col>
            <Col className="numpad_col_digit">
              <Button
                className="numpad_digit"
                onClick={() => {
                  doSendDTMF(6);
                }}
                shape="circle"
                type="primary"
              >
                6
              </Button>
            </Col>
          </Row>
          <Row className={hideItem}>
            <Col className="numpad_col_digit">
              <Button
                className="numpad_digit"
                onClick={() => {
                  doSendDTMF(7);
                }}
                shape="circle"
                type="primary"
              >
                7
              </Button>
            </Col>
            <Col className="numpad_col_digit">
              <Button
                className="numpad_digit"
                onClick={() => {
                  doSendDTMF(8);
                }}
                shape="circle"
                type="primary"
              >
                8
              </Button>
            </Col>
            <Col className="numpad_col_digit">
              <Button
                className="numpad_digit"
                onClick={() => {
                  doSendDTMF(9);
                }}
                shape="circle"
                type="primary"
              >
                9
              </Button>
            </Col>
          </Row>
          <Row className={hideItem}>
            <Col className="numpad_col_digit">
              <Button
                className="numpad_digit"
                onClick={() => {
                  doSendDTMF("*");
                }}
                shape="circle"
                type="primary"
              >
                *
              </Button>
            </Col>
            <Col className="numpad_col_digit">
              <Button
                className="numpad_digit"
                onClick={() => {
                  doSendDTMF(0);
                }}
                shape="circle"
                type="primary"
              >
                0
              </Button>
            </Col>
            <Col className="numpad_col_digit">
              <Button
                className="numpad_digit"
                onClick={() => {
                  doSendDTMF("#");
                }}
                shape="circle"
                type="primary"
              >
                #
              </Button>{" "}
            </Col>
          </Row>
        </div>
        <hr className={hideItem} />
        {/* <Row className="rowhangup">
          <Col className="numpad_col_digit">
            <Button
              className="numpad_digit"
              onClick={handleMute}
              shape="circle"
              type="primary"
            >
              {mute ? <AudioMutedOutlined /> : <AudioOutlined />}
            </Button>
          </Col>
          <Col className="numpad_col_digit active">
            <Button
              className="numpad_digit"
              onClick={handleMute}
              shape="circle"
              type="danger"
            >
              {!mute ? <PlayCircleOutlined /> : <PlayCircleOutlined />}
            </Button>
          </Col>
          <Col className="numpad_col_digit active">
            <Button
              className="numpad_digit"
              onClick={handleMute}
              shape="circle"
              type="danger"
            >
              {!mute ? <UserSwitchOutlined /> : <UserSwitchOutlined />}
            </Button>
          </Col>
          <Col className="numpad_col_digit">
            <Button
              className="numpad_digit"
              onClick={() => {
                handleHangup();
              }}
              shape="circle"
              type="primary"
              danger
              ghost
            >
              <PhoneOutlined />
            </Button>
          </Col>
          
        </Row> */}

        {/* <hr />
     <hr /> */}
        {/* <Row>
     <Col className="numpad_col_digit"><Button className="numpad_digit" onClick={handleHold} shape="circle" type="primary" >{hold?<AudioMutedOutlined  />:<AudioOutlined/>}</Button></Col>
     
     
     </Row> */}

        {(transferStatus !== "1" || transferStatus === "waitForTransfer") && (
          <div>
            <div className={`incomingCallBtnsContainer ${disableClass}`}>
              <div
                className="incomingCallBtnsContainer_item containerColumn flexCenter"
                onClick={handleHangup_numpad}
              >
                <PhoneOutlined icon="fa-solid fa-phone" className="color-red" />
                <span>Hangup</span>
              </div>
              <div
                className="incomingCallBtnsContainer_item containerColumn flexCenter"
                onClick={handleTransfer}
              >
                <UserSwitchOutlined
                  icon="fa-solid fa-arrow-right-arrow-left"
                  className={transferStatus != "" ? "color-red" : ""}
                />
                <span>Transfer</span>
              </div>

              <div
                className="incomingCallBtnsContainer_item containerColumn flexCenter"
                onClick={toggleHold}
              >
                <PlayCircleOutlined
                  icon="fa-solid fa-circle-play"
                  className={onHoldClass}
                />
                <span>Hold</span>
              </div>
              <div
                className="incomingCallBtnsContainer_item containerColumn flexCenter"
                onClick={toggleMute}
              >
                <AudioOutlined className={mutedClass} />
                <span>Mute</span>
              </div>
              <div
                className="incomingCallBtnsContainer_item containerColumn flexCenter"
                onClick={handleTransferCall}
              >
                <QuestionCircleOutlined />
                {/* <AudioOutlined className={mutedClass} /> */}
                <span>Survey</span>
              </div>
            </div>
            <div className="duration">{callStatus}</div>
          </div>
        )}

        {(transferStatus === "waitForTransfer" ||
          transferStatus === "waitForTransfer_Calling" ||
          transferStatus === "waitForTransfer_Calling") && (
          <div className="menuCalToFormcontainer flexCenter">
            <div className="containerColumn  calltoForm calltoForm__transfer">
              <label>Transfer to : </label>

              <div className="containerRow flexCenter">
                <input
                  value={transferTo}
                  onChange={(e) => setTransferTo(e.target.value)}
                />

                <div
                  className="containerRow flexCenter callBtn"
                  onClick={callForTransfer}
                >
                  <PhoneOutlined
                    icon="fa-solid fa-phone"
                    className="color-blue dblock "
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {(transferStatus === "waitForTransfer_Calling" ||
          transferStatus === "waitForTransfer_Answered") && (
          <div className="callforTransferMenu">
            {transferStatus === "waitForTransfer_Calling" && (
              <div
                className="incomingCallBtnsContainer_item containerColumn flexCenter"
                onClick={handleCancelCallTransfer}
              >
                <PhoneOutlined icon="fa-solid fa-phone" className="color-red" />
                <span>Cancel</span>
              </div>
            )}
            <div className="incomingCallBtnsContainer_item containerColumn flexCenter">
              <span>Call to : {sessionTransferTo.current?.myCallTo}</span>
              <span>{myTransferCallStatus}</span>
            </div>
          </div>
        )}

        {transferStatus === "waitForTransfer_Answered" && (
          <div className="menuTransferContaier">
            <div className="callforTransferMenu">
              <div
                className="incomingCallBtnsContainer_item containerColumn flexCenter"
                onClick={handleCancelTransfer}
              >
                <PhoneOutlined icon="fa-solid fa-phone" className="color-red" />
                <span>Cancel Transfer</span>
              </div>
              <div
                className="incomingCallBtnsContainer_item containerColumn flexCenter"
                onClick={() => transferCallsWithReplaces(mysession)}
              >
                <UserSwitchOutlined icon="fa-solid fa-arrow-right-arrow-left" />
                <span>Do Transfer</span>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
