import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  Row,
  Col,
  Drawer,
  Button,
  Typography,
  Input,
  Switch,
  Checkbox,
  Select,
  DatePicker,
} from "antd";
import { PlusCircleFilled } from "@ant-design/icons";

import useAxios from "./../../../CustomHooks/useAxios";
import ShowTable from "./ShowTable";
import ShowTableSum from "./ShowTableSum";
import ShowChart from "./ShowChart";

import PageLink from "./../../PageLink/PageLink";
// import Add from "./Add";

import DebounceSelect from "./../../DebounceSelect";
const { Text } = Typography;
const { Option } = Select;
const dateFormat = "YYYY-MM-DD";
const timeFormat = "HH:mm";
const dateFormat1 = "YYYY-MM-DD HH:mm";
export default function SurveyAfterCall() {
  let { page } = useParams();
  let navigate = useNavigate();
  const { axiosi } = useAxios();

  const user = useSelector((state) => state.loginReducer.user);

  const [sFrom, setSFrom] = useState(
    moment(moment().format(dateFormat), dateFormat1)
  );
  const [sTo, setSTo] = useState(
    moment(moment().add(1, "days").format(dateFormat), dateFormat1)
  );
  const [visibleAdd, setVisibleAdd] = useState(false);
  const [data, setData] = useState();

  const [companyid, setCompanyid] = React.useState({
    value: user.agents[0].company_id,
    label: user.agents[0].gmcomname,
  });
  const [tel, setTel] = useState("");
  const [name, setName] = useState("");

  const [surveyplan_id, setSurveyplan_id] = React.useState();

  const [enableAutoRefresh, setEnableAutoRefresh] = useState(true);
  const [loaddingData, setLoaddingData] = useState(false);
  const [refressSearch, setRefressSearch] = useState(true);
  const hideItem = useSelector((state) => state.loginReducer.hideItem);
  const [enableSearch, setEnableSearch] = useState(true);
  const [agentStatus, setAgentStatus] = useState("");

  const [departmentData, setDepartmentData] = useState(null);
  const [departmentSelected, setDepartmentSelected] = useState("");
  const [exten, setExten] = useState(null);
  const [extenValue, setExtenValue] = useState("");
  const [refreshOptionExten, setRefreshOptionExten] = useState(true);
  const [reportType, setReportType] = useState("summary");
  const handleReportTypeChange = (value) => {
    console.log(`selected ${value}`);
    
    setReportType(value);
    setRefressSearch((prev)=>!prev);
    
  };
  const handleOnChangeIto = async (value) => {
    setExten(value);
    setExtenValue(displayExt(value.value));
  };
  const handleAgentOnClear = () => {
    setExten(null);
    setExtenValue("");
  };
  const handleOnTelChange = (e) => {
    setTel(e.target.value); //e.target.value
  };
  useEffect(() => {
    const getDepartmentData = async () => {
      try {
        const kq = await axiosi.get("department", {
          params: { company_id: companyid.value, pageSize: 100 },
        });
        console.log(kq.data.rows);
        setDepartmentData(kq.data.rows);
        setDepartmentSelected(user && user?.agents[0].log_agent_status_deps);
      } catch (err) {
        setDepartmentData(null);
        console.log("[useEffect]:getDepartmentData has an Error !");
      }
    };
    getDepartmentData();
  }, [companyid?.value]);

  useEffect(() => {
    setExten(null);

    setRefreshOptionExten((pre) => !pre);
  }, [companyid?.value]);

  useEffect(() => {
    doSearch();
  }, [refressSearch, page]);

  async function fetchUserList(company_name) {
    console.log("fetching user", company_name);
    const body = await axiosi.get("companies", {
      params: { company_name, pageSize: 100 },
    });
    if (body?.data?.rows) {
      return body?.data?.rows.map((user) => ({
        label: user.company_name,
        value: user.id,
      }));
    } else {
      const a = [{ label: "---no data---", value: "" }];
      return a.map((i) => i);
    }
  }
  async function fetchSurveyPlan(surveyplan_name) {
    console.log("fetchSurveyPlan", surveyplan_name);
    const body = await axiosi.get("surveyplan", {
      params: { company_id: companyid, name: surveyplan_name, pageSize: 100 },
    });
    if (body?.data?.rows) {
      return body?.data?.rows.map((user) => ({
        label: user.name,
        value: user.id,
      }));
    } else {
      const a = [{ label: "---no data---", value: "" }];
      return a.map((i) => i);
    }
  }
  const handleAdd = () => {
    console.log("[handleAdd]:");
    setVisibleAdd(true);
  };
  const onCloseAdd = () => {
    setVisibleAdd(false);
  };
  const handleSearch = async () => {
    console.log("[handleSearch]");
    setRefressSearch((pre) => !pre);
  };
  const doSearch_agentStatus = async () => {
    // console.log("[doSearch]");
    // navigate('/ivrplanmain');
    if (!enableSearch) return;
    try {
      setLoaddingData(true);
      const kq = await axiosi.get("queues/agentStatusb", {
        params: {
          page,
          tel,
          name,
          surveyplan_id: surveyplan_id?.value,
          company_id: companyid?.value,
          department_id: departmentSelected,
          agentStatus,
        },
      });
      // console.log("[handleSearch]:kq=",kq);
      if (!enableSearch) return;
      setLoaddingData(false);
      setData(kq.data);
      if (enableAutoRefresh) {
        if (!enableSearch) return;
        setTimeout(() => {
          setRefressSearch((pre) => !pre);
        }, 3000);
      }
    } catch (err) {
      console.log("ERROR");
      setLoaddingData(false);
    }
  };
  const doSearch = async () => {
    try {
      setLoaddingData(true);
      let timeFrom = "";
      if (typeof sFrom === "object") {
        timeFrom = sFrom.format(dateFormat1);
        console.log("sFrom=", sFrom.format(dateFormat1));
      } else {
        console.log("sFrom STRING=", sFrom);
        if (sFrom) {
          timeFrom = sFrom;
          console.log("CÓ sFROM");
        } else {
          console.log("KG CÓ SFORM");
        }
      }

      let timeTo = "";
      if (typeof sTo === "object") {
        timeTo = sTo.format(dateFormat1);
        console.log("sTo:=", sTo.format(dateFormat1));
      } else {
        timeTo = sTo;
        console.log("sTo STRING:=", sTo);
      }

      const kq = await axiosi.get("survey_after_call", {
        params: {
          page,
          tel,
          timeFrom,
          timeTo,
          tel,
          sexten: exten?.value,
          reportType,
          company_id: companyid?.value,
          department_id: departmentSelected,
        },
      });
      console.log("[handleSearch]:kq=", kq);
      // if (!enableSearch) return;
      setLoaddingData(false);
      setData(kq.data);
      // if (enableAutoRefresh) {
      //   if (!enableSearch) return;
      //   setTimeout(() => {
      //     setRefressSearch((pre) => !pre);
      //   }, 3000);
      // }
    } catch (err) {
      console.log("ERROR");
      setLoaddingData(false);
    }
  };
  async function onChangeEnableRefresh(checked) {
    console.log(`switch to :`, checked.target.checked);
    setEnableAutoRefresh(checked.target.checked);
    if (!loaddingData && checked.target.checked) {
      setTimeout(() => {
        setRefressSearch((pre) => !pre);
      }, 3000);
    }
  }
  const handleOnChangeDepartment = (value) => {
    console.log("[handleOnChangeDepartment]:value=", value);
    setDepartmentSelected(value);
    setExten(null);
  };
  const handleOnChangeAgentStatus = (value) => {
    console.log("[handleOnChangeAgentStatus]:value=", value);
    setAgentStatus(value);
  };

  async function fetchExtenList(exten) {
    console.log("[fetchExtenList]:exten=", exten);
    const body = await axiosi.get("agents/searchExten", {
      params: {
        company_id: companyid?.value,
        department_id: departmentSelected,
        exten,
        pageSize: 200,
      },
    });
    if (body?.data?.rows) {
      return body?.data?.rows.map((user) => ({
        label: user.name,
        value: user.endpoint_id,
      }));
    } else {
      const a = [{ label: "---no data---", value: "" }];
      return a.map((i) => i);
    }
  }
  const displayExt = (endpointid) => {
    // console.log("[displayExt]:endpointid=",endpointid);
    if (endpointid) {
      const ext = endpointid.split("_");
      return ext[0];
    } else {
      return "";
    }
  };
  function checkLogDep(item) {
    if (user.agents[0].company_id === 1) return true;
    const logdeps = user.agents[0].log_agent_status_deps;

    return logdeps.includes(item.id);
  }
  function onChangeSFrom(date, dateString) {
    setSFrom(dateString);
  }
  function onChangeSTo(date, dateString) {
    // console.log(date, dateString);
    setSTo(dateString);
  }
  return (
    <>
      <section className="search">
        <div className="row">
          <Typography.Title level={3}>Search</Typography.Title>
        </div>

        <div>
          {/* <Typography.Title level={3}>Search :</Typography.Title> */}
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={4}>
              <div>
                <label>From:</label>
                <DatePicker
                  style={{ width: "100%" }}
                  className={"minWidth2"}
                  showTime
                  onChange={onChangeSFrom}
                  // onSelect={onSelectSFrom}
                  // onClear={onClearSFrom}
                  // value={moment(sFrom,dateFormat1)}
                  defaultValue={sFrom}
                  format={dateFormat1}
                />
              </div>
            </Col>
            <Col className="gutter-row" span={4}>
              <div>
                <label>To :</label>
                <DatePicker
                  style={{ width: "100%" }}
                  className={"minWidth2"}
                  showTime
                  onChange={onChangeSTo}
                  defaultValue={sTo}
                  format={dateFormat1}
                />
              </div>
            </Col>
            <Col className="gutter-row" span={4}>
              <div>
                <label>Tel:</label>
                <Input
                  style={{ width: "100%" }}
                  className={"minWidth2"}
                  placeholder="tel"
                  value={tel}
                  onChange={handleOnTelChange}
                />
              </div>
            </Col>
            <Col className="gutter-row" span={4}>
              <div>
                <label>Agent :</label>
                <DebounceSelect
                  className={"minWidth2"}
                  selectMode={""}
                  refreshOption={refreshOptionExten}
                  showSearch={true}
                  value={exten}
                  placeholder=""
                  fetchOptions={fetchExtenList}
                  onChange={handleOnChangeIto}
                  onClear={handleAgentOnClear}
                  style={{ width: "100%" }}
                />
              </div>
            </Col>
            <Col className="gutter-row" span={4}>
              <div>
                <Text>Report Type:</Text>
                <Select
                  style={{ width: "100%" }}
                  defaultValue="summary"
                  onChange={handleReportTypeChange}
                >
                  <Option value="details">Details</Option>
                  <Option value="summary">Summary</Option>
                </Select>
              </div>
            </Col>
            <Col className="gutter-row" span={4}>
              <div className={hideItem}>
                <label>Company :</label>
                <DebounceSelect
                  showSearch={true}
                  value={companyid}
                  placeholder="Select a company !"
                  fetchOptions={fetchUserList}
                  onChange={(newValue) => {
                    setCompanyid(newValue);
                  }}
                  style={{ width: "100%" }}
                />
              </div>
            </Col>
          </Row>

          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={16}>
              <div>
                <Text>Department :</Text>
                <Select
                  mode="multiple"
                  style={{ width: "100%" }}
                  onChange={handleOnChangeDepartment}
                  value={departmentSelected}
                >
                  <Option value=""></Option>
                  {departmentData &&
                    departmentData.map((item) => {
                      if (checkLogDep(item)) {
                        return (
                          <Option key={item.id} value={item.id}>
                            {item.name}
                          </Option>
                        );
                      }
                    })}
                </Select>
              </div>
            </Col>
            <Col className="gutter-row" span={4}>
              <div>
                <Text>&nbsp;</Text>
                <Button onClick={handleSearch}>Search</Button>
              </div>
            </Col>
            {/* <Col className="gutter-row" span={4}>
              <div>
                <Text>Export:</Text>
                <Button onClick={handleSearch}>Export :</Button>
              </div>
            </Col> */}
          </Row>
        </div>
      </section>
      <section className="searchResult">
        <div className="row">
          <Typography.Title level={3}>Result</Typography.Title>
        </div>

        <div className="row">
          {data?.rows && (
            <>
              {reportType === "summary" ? (
               <div style={{flex:1,display:"flex",flexDirection:"column",gap:"1rem"}}>
                   <ShowChart data={data?.rows} /> 
                   <ShowTableSum
                  departmentSelected={departmentSelected}
                  exten={extenValue}
                  agentStatus={agentStatus}
                  data={data?.rows}
                  setRefressSearch={setRefressSearch}
                />
               
               </div>
              ) : (
                <ShowTable
                  departmentSelected={departmentSelected}
                  exten={extenValue}
                  agentStatus={agentStatus}
                  data={data?.rows}
                  setRefressSearch={setRefressSearch}
                />
              )}
            </>
          )}
        </div>
        <div className="row">
          {data?.pages > 1 ? (
            <PageLink
              pageSize={data.pageSize}
              searchpage={"surveyac"}
              pages={data.pages}
              numrows={data.numrows}
              page={data.page}
            />
          ) : (
            <h1>chi co' 1 page</h1>
          )}
        </div>
      </section>
    </>
  );
}
